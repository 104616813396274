import { ref, computed } from 'vue';
import { client as apolloClient } from '@/config/apolloClient';
import { getLogType, getScoreType } from './log_type';
import store from '../store/index'

const state = ref({
    user_id: '',
    user: null,
    scenarios: [],
    activities: [],
    sequences: [],
    modules: [],
    maxScoresByModule:{},
    maxScoresByType: {},
    minScoresByModule: {},
    //Old values retrieved the first time the jauges are shown
    oldScoresByModule: {
        quality: {},
        legal: {},
        actor: {}
    },
    //Serves to know if the jauges had been showned once or not
    initialized: {
        quality: false,
        legal: false,
        actor: false
    },
    firstLevelFinished: false,
    courseDoneDate: {},
    finishedAllCourseDate: null,
});

export const setState = (newState) => {
    state.value = { ...newState };
};

export const getState = computed(() => state.value);
export const getUserSequences = computed(() => state.value.sequences);
export const userHasAccess = computed(() => {
    if (!state.value.user) {
        return null
    }

    const nowTimestamp = Date.now()
    const userGroup = state.value.user.groups?.[0]?.group

    if (state.value.user.disabled || !userGroup) {
      return false
    } else if (userGroup.disabled || nowTimestamp < Date.parse(userGroup.start_date)  || nowTimestamp > Date.parse(userGroup.end_date)) {
      return false
    }

    return true
});

export const initUserLog = async() => {
    const {data} = await apolloClient.query({
        query: require('@/gql/logs/get_user_logs.gql'),
        variables: {
            USER_ID: state.value.user_id, 
        },
        fetchPolicy: 'network-only'
    });
    state.value.activities = []
    state.value.modules = []
    let indexMissionFinished = 0

    data.user_log.forEach((ul) => {
        const log = { ...ul };
        log.data = { ...log.data };
        delete log.__typename;

        if (log.log_type_id === getLogType('activity').id) {
            store.dispatch('gamestatus/addActivitiesFinished',  log.data_key)
            state.value.activities.push(log);
        } else if ( log.log_type_id === getLogType('scenario').id) {
            state.value.scenarios.push(log);
            store.dispatch('gamestatus/addScenarioFinished',  log.log_type_id)

        } else if ( log.log_type_id === getLogType('sequence').id) {
            state.value.sequences.push(log);
        } else if ( log.log_type_id === getLogType('module').id) {
            console.log('data', log)
            if (log.data.isQuest){
                console.log('ADD QUEST', log)
                store.dispatch('gamestatus/addQuestFinished', log.data_key)
            } else {
                console.log('ADD MISSION', log.data_key)
                store.dispatch('gamestatus/addMissionFinished', log.data_key)
                indexMissionFinished++;
            }
            state.value.modules.push(log)
        } else if ( log.log_type_id === getLogType('course').id) {
            state.value.courseDoneDate[log.data_key] = new Date(log.created_at)
        }
    });

    if (indexMissionFinished >= 4){
        state.value.firstLevelFinished = true
    }

    if (Object.keys(state.value.courseDoneDate).length > 1) {
        // Get last course done date
        Object.keys(state.value.courseDoneDate).forEach((key) => {
            const doneDate = state.value.courseDoneDate[key]

            if (!state.value.finishedAllCourseDate || state.value.finishedAllCourseDate.getTime() < doneDate.getTime()) {
                state.value.finishedAllCourseDate = doneDate
            }
        })
    }
}

export const IsFirstLevelFinished = () => { return state.value.firstLevelFinished }
export const getFinishedAllCourseDate = () => { return state.value.finishedAllCourseDate }

export const setUser = (user) => {
    state.value.user_id = user.id;
    state.value.user = user;
}

const storeLog = async (data) => {
    const {data: response} = await apolloClient.mutate({
        mutation: require('@/gql/logs/store_user_log.gql'),
        variables: { USER_LOG: data },
    });
    return response.insert_user_log.returning[0].id;
};

export const MaxTotalPoints = (scoresByModule) => {
    state.value.maxScoresByModule = scoresByModule;
    state.value.maxScoresByType = {}
    for (const moduleID in state.value.maxScoresByModule) {
        if (Object.hasOwnProperty.call(state.value.maxScoresByModule, moduleID)) {
            const scores = state.value.maxScoresByModule[moduleID];
            for (const scoreKey in scores) {
                if (Object.hasOwnProperty.call(scores, scoreKey)) {
                    const score = scores[scoreKey];
                    if (!state.value.maxScoresByType[scoreKey])
                        state.value.maxScoresByType[scoreKey] = 0
                    state.value.maxScoresByType[scoreKey] += score
                }
            }
        }
    }
}

export const MinPointsPerModule = (minPerModule) => {
    state.value.minScoresByModule = minPerModule
}

export const minPointsByModule = computed(() => {
    return state.value.minScoresByModule
})

export const maxPointsByModule = computed(() => {
    let max = state.value.maxScoresByModule
    return max
})

// POINTS
export const totalQualityPoints = computed(() => {
    let score = {
        totalScore: 0
    };
    if (state.value.activities.length > 0) {
        state.value.activities.forEach((a) => {
            if (a.data.score_type === getScoreType('quality').id) {
                score.totalScore += a.data.score ? a.data.score : 0;
                if (typeof a.data.moduleId !== "undefined" && a.data.moduleId && a.data.moduleId != -1){
                    if (!score[a.data.moduleId]){
                        score[a.data.moduleId] = 0;
                    }
                    //Stores the score for a module of Quality points
                    score[a.data.moduleId] += a.data.score ? a.data.score : 0;

                    //Sets the initial scores in order to animate the SkillProgress bar (from score->to score)
                    if (!state.value.initialized.quality){
                        if (!state.value.oldScoresByModule.quality[a.data.moduleId]) { 
                            state.value.oldScoresByModule.quality[a.data.moduleId] = 0 
                        }
                        state.value.oldScoresByModule.quality[a.data.moduleId] += a.data.score ? a.data.score : 0;
                    }
                }
            }
        })
    }
    score.maxScore = state.value.maxScoresByType['quality']
    state.value.initialized.quality = true;
    return score;
});

export const totalActorPoints = computed(() => {
    let score = {
        totalScore: 0
    };
    if (state.value.activities.length > 0) {
        state.value.activities.forEach((a) => {
            if (a.data.score_type === getScoreType('actor').id) {
                score.totalScore += a.data.score ? a.data.score : 0;
                if (typeof a.data.moduleId !== "undefined" && a.data.moduleId && a.data.moduleId != -1){
                    if (!score[a.data.moduleId]){
                        score[a.data.moduleId] = 0;
                    }
                    score[a.data.moduleId] += a.data.score ? a.data.score : 0;

                    if (!state.value.initialized.actor){
                        if (!state.value.oldScoresByModule.actor[a.data.moduleId]) { 
                            state.value.oldScoresByModule.actor[a.data.moduleId] = 0 
                        }
                        state.value.oldScoresByModule.actor[a.data.moduleId] += a.data.score ? a.data.score : 0;
                    }
                }
            }
        })
    }
    score.maxScore = state.value.maxScoresByType['actor']
    state.value.initialized.actor = true;
    return score;
});

export const totalLegalPoints = computed(() => {
    let score = {
        totalScore: 0
    };
    if (state.value.activities.length > 0) {
        state.value.activities.forEach((a) => {
            if (a.data.score_type === getScoreType('legal').id) {
                score.totalScore += a.data.score ? a.data.score : 0;
                if (typeof a.data.moduleId !== "undefined" && a.data.moduleId && a.data.moduleId != -1){
                    if (!score[a.data.moduleId]){
                        score[a.data.moduleId] = 0;
                    }
                    score[a.data.moduleId] += a.data.score ? a.data.score : 0;

                    if (!state.value.initialized.legal){
                        if (!state.value.oldScoresByModule.legal[a.data.moduleId]) { 
                            state.value.oldScoresByModule.legal[a.data.moduleId] = 0 
                        }
                        state.value.oldScoresByModule.legal[a.data.moduleId] += a.data.score ? a.data.score : 0;
                    }
                }
            }
        })
    }
    score.maxScore = state.value.maxScoresByType['legal']
    state.value.initialized.legal = true;
    return score;
});

// ACTIVITIES
export const getActivityIndex = (activity_id) =>  state.value.activities.indexOf(state.value.activities.find((a) => a.data_key === activity_id));
export const setScoreActivity = async  (activity, success ) => {
    let indexActivity = getActivityIndex(activity.id);
    // console.log(indexActivity);
    if ( indexActivity === -1 ) {
        state.value.activities.push({
            data: {
                score: success ? (activity.scores[0]?.score ?? null) : null,
                score_type: activity.scores[0]?.score_type_id ?? null,
                moduleId: activity.moduleId
            },
            data_key: activity.id,
            user_id: state.value.user_id,
            log_type_id: getLogType('activity').id,
        });
        indexActivity = state.value.activities.length - 1;
        store.dispatch('gamestatus/addActivitiesFinished',  activity.id)
        if(success && activity && activity.scores && activity.scores.length > 0) {
            console.log('SCORE:', activity.scores[0]?.score, success, activity.score,  activity)
            store.dispatch('gamestatus/addScoreTo', {scoreType: activity.scores[0].type.slug, points: activity.scores[0]?.score})
        }
    } else {
         if ( state.value.activities[indexActivity].data.score === null) {
            state.value.activities[indexActivity].data.score = success ? (activity.scores[0]?.score ?? null) : null;
            state.value.activities[indexActivity].data.score_type = success ? activity.scores[0]?.score_type_id : null
            if(success && activity && activity.scores && activity.scores.length > 0){
                store.dispatch('gamestatus/addScoreTo', {scoreType: activity.scores[0].type.slug, points: activity.scores[0]?.score })
            }
        }
        if (typeof state.value.activities[indexActivity].data.moduleId === "undefined"){
            state.value.activities[indexActivity].data.moduleId = activity.moduleId
        }
    }
    const new_activity_id = await storeLog( state.value.activities[indexActivity] );
    if (!state.value.activities[indexActivity].id) {
        state.value.activities[indexActivity].id = new_activity_id;
    }
};


export const getSequenceIndex = (sequence_id) =>  state.value.sequences.indexOf(state.value.sequences.find((s) => s.data_key === sequence_id));
export const userHasSequence = (sequence_id) => {
    return state.value.sequences.find((s) => s.data_key === sequence_id);
};
export const userHasActivityIn = (activities) => {
    // console.log(activities);
    // console.log(state.value.activities);
    let hasActivities = false;
    if (state.value.activities.forEach((ua) => {
        activities.forEach((a) => {
            if (a.id === ua.data_key) hasActivities = true;
        });
    }));
    return hasActivities;
} 
export const setSequence = async (sequence_id, type) => {
    // console.log(sequence_id, type);
    // console.log(state.value.sequences);

    let indexSequences= getSequenceIndex(sequence_id);
    
    // console.log("INDEX SEQUZNCE", indexSequences);
    if ( indexSequences === -1 ) {
        state.value.sequences.push({
            data: {
               status: type ?? 'pending'
            },
            data_key: sequence_id,
            user_id: state.value.user_id,
            log_type_id: getLogType('sequence').id,
        });
        indexSequences = state.value.sequences.length - 1; 
    } else {
        // Don't update sequence status if it's already completed
        if (state.value.sequences[indexSequences].data.status === 'success') {
            return
        }
        state.value.sequences[indexSequences].data.status = type ?? 'pending' ;
    }
    
    // console.log( state.value.sequences[indexSequences] );
    const new_sequence_id = await storeLog( state.value.sequences[indexSequences] ); 
    
    // console.log(new_sequence_id);
    if (!state.value.sequences[indexSequences].id) {
        state.value.sequences[indexSequences].id = new_sequence_id;
    }
};

export const getModuleIndex = (module_id) =>  state.value.modules.indexOf(state.value.modules.find((s) => s.data_key === module_id));

export const setModuleDone = async (module_id, isQuest) => {
    let moduleIndex = getModuleIndex(module_id)

    if (moduleIndex === -1){
        state.value.modules.push({
            data: {
                done: true,
                isQuest: isQuest
            },
            data_key: module_id,
            user_id: state.value.user_id,
            log_type_id: getLogType('module').id
        })
        moduleIndex = state.value.modules.length - 1
        console.log('module state', state.value.modules)
        console.log('storing:', state.value.modules[moduleIndex], module_id)
        await store.dispatch('gamestatus/addQuestFinished', module_id)


        const new_module_id = await storeLog( state.value.modules[moduleIndex]);
        if (!state.value.modules[moduleIndex].id){
            state.value.modules[moduleIndex].id = new_module_id;
        }

        // Check if we have finished this course
        const currentCourse = (store.state.course.courses && store.state.course.courses[0])
        const modulesDone = state.value.modules.reduce((modulesDone, log) => {
            modulesDone[log.data_key] = true

            return modulesDone
        }, {})
        const hasDoneAllModules = (currentCourse && currentCourse.modules.every(module => !!modulesDone[module.id]))

        if (hasDoneAllModules) {
            state.value.firstLevelFinished = true
            await setCourseDone(currentCourse.id)

            const doneDate = new Date()

            state.value.courseDoneDate[currentCourse.id] = doneDate
            
            if (Object.keys(state.value.courseDoneDate).length > 1) {
                state.value.finishedAllCourseDate = doneDate
            }
        }
    }
}

export const setCourseDone = async (course_id) => {
    await storeLog({
        data: null,
        data_key: course_id,
        user_id: state.value.user_id,
        log_type_id: getLogType('course').id
    })
}

export const addLoginLog = async () => {
    await storeLog({
        data: null,
        data_key: null,
        user_id: state.value.user_id,
        log_type_id: getLogType('login').id
    })
}

export const addLogoutLog = async () => {
    await storeLog({
        data: null,
        data_key: null,
        user_id: state.value.user_id,
        log_type_id: getLogType('logout').id
    })
}

/**
 * Check user logs against courses data to make sure progress are tracked correctly.
 * This seem to be needed for some edge case and with old user progression data.
 **/
export const checkUserLogs = async (courses) => {
    if (!courses || courses.length <= 0) {
        return
    }

    for (let i = 0; i < courses.length; i += 1) {
        const course = courses[i]
        const isQuest = (course.type.slug === 'investigation')

        // Early return if the course is already done
        if (state.value.courseDoneDate[course.id] && state.value.firstLevelFinished) {
            continue
        }

        let moduleDoneCount = 0
        let missionFinishedCount = 0

        for (let j = 0; j < course.modules.length; j += 1) {
            const module = course.modules[j]

            // Early return if the module is already done with the right type
            const moduleLogIndex = getModuleIndex(module.id)
            if (moduleLogIndex > -1) {
                const moduleLog = state.value.modules[moduleLogIndex]
                if (moduleLog.data.isQuest === isQuest) {
                    moduleDoneCount += 1
                    
                    if (!isQuest) {
                        missionFinishedCount += 1
                    }
                    continue
                }
            }
            
            // Check if module is done with his sequence list
            const moduleDone = module.sequences.every((sequence) => userHasSequence(sequence.id))

            // Update module state if needed
            if (moduleDone) {
                moduleDoneCount += 1
                await setModuleDone(module.id, isQuest)

                if (isQuest) {
                    await store.dispatch('gamestatus/addQuestFinished', module.id)
                } else {
                    missionFinishedCount += 1
                    await store.dispatch('gamestatus/addMissionFinished', module.id)
                }
            }
        }

        // Check if all mission are done
        if (missionFinishedCount >= 4){
            state.value.firstLevelFinished = true
        }

        // Check if course is done with his done module count
        if (moduleDoneCount >= course.modules.length) {
            await setCourseDone(course.id)
        }
    }
}
