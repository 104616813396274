import enquete from "./modules/enquete";
import gamestatus from "./modules/gamestatus";
import course from "./modules/course";
import { createStore } from 'vuex'
import VuexPersistence from 'vuex-persist'

const vuexLocal = new VuexPersistence({
    storage: window.localStorage,
    modules:['gamestatus']
})

export default createStore({
    state: {
        currentTheme: 'midnight-blue'
    },
    actions:{
        async setTheme({commit}, theme){
            commit('changeTheme', theme)
        },
    },
    mutations: {
        changeTheme(state, theme) {
            state.currentTheme = theme
        },
    },
    modules:{
        gamestatus,
        enquete,
        course
    },
    plugins: [vuexLocal.plugin]
})
