import {client as apolloClient} from '../../config/apolloClient'
import { MaxTotalPoints, MinPointsPerModule, checkUserLogs } from '../../composables/user_log'


export default {
    namespaced: true,
    state: {
        'courses': []
    },
    actions:{
        async getCourses({ commit, dispatch }, type){
            // console.log('TYPE', type)
           return apolloClient.query({
               query: require('@/gql/courses/find_all_shallow.gql'),
            //    variables: {
            //        TYPE: type,
            //    }
           }).then(async resultQuery => {
               let [courses] = await Promise.all([resultQuery.data.item])
               let minPointsPerModule = {}
               // Load score data for each module
               const scoresByModule = await dispatch('getModulesScores', courses)
               //Hack to not completely change the logic of EVERYTHING but still gets the full scores
                let currentCourse = courses.find(course => course.type.slug === type)
                currentCourse = [currentCourse]
                courses = currentCourse.map(n => {
                   const c = Object.assign({}, n)
                   if (Array.isArray(n.metas)) {
                       c.metas = c.metas.reduce((dict, meta) => {
                           dict[meta.type.slug] = meta.value
                           return dict
                       }, {})
                   }else{
                       c.metas = {}
                   }
                   c.modules = c.modules.map(mo =>{
                       const m = Object.assign({}, mo)

                       // Add scores data
                       m.scores = (scoresByModule[m.id] || {})

                       // Add metas
                       if (Array.isArray(m.metas)) {
                           m.metas = m.metas.reduce((dict, meta) => {
                               dict[meta.type.slug] = meta.value

                               //Add minimum necessary points for the current module
                               if (typeof minPointsPerModule[m.id] === "undefined"){
                                   minPointsPerModule[m.id] = {
                                       actor: 0,
                                       quality: 0,
                                       legal: 0
                                   }
                               }
                               if (meta.type.slug === "quality" || meta.type.slug === "actor" || meta.type.slug === "legal"){
                                   minPointsPerModule[m.id][meta.type.slug] = meta.value
                               }
                               return dict
                           }, {})
                       }else{
                           m.metas = {}
                       }

                       if (m.sequences) {
                           m.sequences = m.sequences.map(sq =>{
                             const s = Object.assign({}, sq)

                             if (Array.isArray(s.metas)) {
                                 s.metas = s.metas.reduce((dict, meta) => {
                                     dict[meta.type.slug] = meta.value
                                     return dict
                                 }, {})
                             }else{
                                 s.metas = {}
                             }

                             return s
                         })
                       }

                       return m
                   })
                   return c;
               })
               MinPointsPerModule(minPointsPerModule)

               await checkUserLogs(courses)
               
               commit('setCourses', courses)
           })
        },
        async getModulesScores(context, courses) {
            if (!courses)
                return {}

            // Get all activity ids from module, sequence and scenarios and create a map of module => activity ids
            const data = courses.reduce((data, course) => {
                course.modules.forEach((module) => {
                    module.sequences.forEach((sequence) => {
                        sequence.scenarios.forEach((seqsc) => {
                            seqsc.scenario.nodes.forEach((node) => {
                                if (node.type.slug == 'activity') {
                                    data.ids.push(node.node_content)

                                    if (!data.idsByModule[module.id]) {
                                        data.idsByModule[module.id] = []
                                    }
                                    
                                    data.idsByModule[module.id].push(node.node_content)
                                }
                            })
                        })
                    })
                })

                return data
            }, {
                ids: [],
                idsByModule: {}
            })

            // Get scores data for all activity ids
            const scores = await apolloClient.query({
                query: require('@/gql/activities/score_by_activity_ids.gql'),
                variables: {
                    ids: data.ids,
                }
            })

            if (!scores.data || !scores.data.activity_score) {
                return {}
            }

            // Create a map of activity id => score data
            const scoreByActivityId = scores.data.activity_score.reduce((data, activityScore) => {
                data[activityScore.activity_id] = {
                    score: activityScore.score,
                    type: activityScore.type.slug
                }
                return data
            }, {})

            // Return a map of module id => score sum by type
            let scoresByModuleIds =  Object.keys(data.idsByModule).reduce((moduleScores, moduleId) => {
                moduleScores[moduleId] = data.idsByModule[moduleId].reduce((scoreByType, activityId) => {
                    if (!scoreByActivityId[activityId]) {
                        return scoreByType
                    }

                    const { score, type } = scoreByActivityId[activityId]

                    if (!scoreByType[type]) {
                        scoreByType[type] = 0
                    }

                    scoreByType[type] += score
                    return scoreByType
                }, {})
                
                return moduleScores
            }, {})
            MaxTotalPoints(scoresByModuleIds)

            return scoresByModuleIds;
        },
    },
    mutations: {
        setCourses(state, courses) {
            state.courses = courses
        },
    }
}
