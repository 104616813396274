import { ref } from 'vue';
import { client as apolloClient } from '@/config/apolloClient';

const stateLogType = ref();
const stateScoreType = ref();
export const getLogType = (type) => stateLogType.value.find((l) => l.slug === type);
export const getScoreType = (type) => stateScoreType.value.find((l) => l.slug === type);
export const initLogTypes = async() => {
    const {data} = await apolloClient.query({
        query: require('@/gql/logs/get_log_types.gql'),
    });
    stateLogType.value = data.log_type;
    stateScoreType.value = data.score_type;
    // console.log(stateLogType.value);
}