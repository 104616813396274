import { ApolloClient, createHttpLink, InMemoryCache, from } from '@apollo/client/core'
import {onError} from "@apollo/client/link/error";
import {useAuth} from "../composables/auth";
import { GC_AUTH_TOKEN } from '@/constants/jwt'
const errorLink = onError(({ graphQLErrors, networkError, forward, operation}) => {
    if (graphQLErrors)

        graphQLErrors.map( async (error) => {

        if(error.extensions.code == 'invalid-jwt') {

            const {refreshToken, logout} = useAuth();
            let success = await refreshToken()
            if(success) {
                return forward(operation);
            }else {
                await logout();
            }

        }else {
            console.log(`[GraphQL error]: Message: ${error.message}, Code: ${error.extensions.code}, Path:  ${error.extensions.path}`,)
        }
    })

    if (networkError) console.log(`[Network error]: ${networkError}`)
})


// Cache implementation
const cache = new InMemoryCache()

// Create the apollo client
let client

const createClient = () => {
    const httpLink = createHttpLink({
        // You should use an absolute URL here
        uri: process.env.VUE_APP_HASURA_API,
        headers: {
            authorization:
                localStorage.getItem(GC_AUTH_TOKEN) ?
                `Bearer ${localStorage.getItem(GC_AUTH_TOKEN)}` :
                ''
        }
    })
    const additiveLink = from([
        errorLink,
        httpLink,
    ]);
    client = new ApolloClient({
        link: additiveLink,
        cache,
    })

}


export {client, createClient}


