<template>
  <div>
    <div :class="`text-center flex justify-between mb-4 ${currentTheme}`">
      <div class="font-bold grand-text title">
        {{$t.scoreoverview.titre_competences}}
      </div>
      <div class="cursor-pointer text-sm mt-2 underline"  :hidden="true">
        <div class="tooltip">{{$t.scoreoverview.bouton_question}}
          <span class="tooltiptext" >Si vous êtes inscrit à notre formation, un lien d'invitation vous à été transmis par mail, sinon, envoyer un mail à <a :href="'mailto:' + $t.common.support_email" class="underline">{{$t.common.support_email}}</a></span>
        </div>
      </div>
    </div>
    <div>
      <SkillsProgressBar :isHome="isHome" skill="juridique" :min="minPointsNecessary('legal')" v-if="maxPoints('legal')" :max="maxPoints('legal')" :value="currentPoints('legal')"></SkillsProgressBar>
      <SkillsProgressBar :isHome="isHome" skill="copro" :min="minPointsNecessary('actor')" v-if="maxPoints('actor')" :max="maxPoints('actor')" :value="currentPoints('actor')"></SkillsProgressBar>
      <SkillsProgressBar :isHome="isHome" skill="qualite" :min="minPointsNecessary('quality')" v-if="maxPoints('quality')" :max="maxPoints('quality')" :value="currentPoints('quality')"></SkillsProgressBar>
    </div>
  </div>
</template>

<script>
import SkillsProgressBar from "./SkillsProgressBar";
import { totalLegalPoints, totalActorPoints, totalQualityPoints, maxPointsByModule, minPointsByModule, setModuleDone } from '@/composables/user_log';

import { mapState } from 'vuex'

export default {
  components: {SkillsProgressBar},
  emits:['status', 'bypass'],
  props: {
    moduleID:{
      type: String,
      default: '',
      required: false
    },
    //Serves to see if the precedent screen was a Quest or Mission
    isHome: {
      type: String,
      default: '',
      required: false
    },
    bypass: {
      type: Boolean,
      default: false,
    }
  },
  setup () {
    return {
      totalLegalPoints,
      totalActorPoints,
      totalQualityPoints,
      maxPointsByModule,
      minPointsByModule,
      setModuleDone
    }
  },
  async mounted(){
    if (this.moduleID){
      if (this.isHome === 'true'){
        //Automatically validate module
        await this.setModuleDone(this.moduleID, false)
      } else {
        if (this.currentPoints('legal') >= this.minPointsNecessary('legal') && this.currentPoints('actor') >= this.minPointsNecessary('actor')
          && this.currentPoints('quality') >= this.minPointsNecessary('quality')){
          await this.setModuleDone(this.moduleID, true)
          this.win = true
          this.$emit('status', 'win')
        }
        else{
          if(this.currentRattrapageDone){
            this.win = true
            this.$emit('status', 'win')
          }else{
            this.win = false
            this.$emit('status', 'loose')
            this.$store.dispatch('gamestatus/setCurrentRattrapageDone', false)
          }
        }
      }
    }
  },
  computed: {
    minPointsNecessary(){
      return type => {
        if (!this.moduleID || this.moduleID === -1) {
          return 0
        }
        return parseInt(this.minPointsByModule[this.moduleID][type])
      }
    },
    maxPoints(){
      return type => {
          if (!this.moduleID || this.moduleID === -1){
            switch (type) {
              case 'legal':
                return this.totalLegalPoints.maxScore
            case 'actor':
              return this.totalActorPoints.maxScore
            case 'quality':
              return this.totalQualityPoints.maxScore
          }
        } else {
          if (typeof this.maxPointsByModule[this.moduleID] === "undefined" || typeof this.maxPointsByModule[this.moduleID][type] === "undefined")
              return 0
          return this.maxPointsByModule[this.moduleID][type]
        }
        return 0
      } 
    },
    currentPoints(){
      return type => {
        if (!this.moduleID || this.moduleID === -1){
          switch (type) {
            case 'legal':
              return this.totalLegalPoints.totalScore ? this.totalLegalPoints.totalScore : 0
            case 'actor':
              return this.totalActorPoints.totalScore ? this.totalActorPoints.totalScore : 0
            case 'quality':
              return this.totalQualityPoints.totalScore ? this.totalQualityPoints.totalScore : 0
          }
        } else {
          switch (type) {
            case 'legal':
              return this.totalLegalPoints[this.moduleID] ? this.totalLegalPoints[this.moduleID] : 0
            case 'actor':
              return this.totalActorPoints[this.moduleID] ?  this.totalActorPoints[this.moduleID] : 0
            case 'quality':
              return this.totalQualityPoints[this.moduleID] ? this.totalQualityPoints[this.moduleID] : 0
          }
        }
        return 0
      }
    },
    ...mapState({
      activitiesFinished: state => state.gamestatus.activitiesFinished,
      currentRattrapageDone: state => state.gamestatus.currentRattrapageDone,
      currentTheme: state => state.currentTheme,
      missionFinished: state => state.gamestatus.missionFinished,
      currentModule: state => state.gamestatus.currentModule,

    }),
  }
}

</script>

<style lang="scss" scoped>
/* Tooltip container */
.tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted lightgoldenrodyellow; /* If you want dots under the hoverable text */
}

/* Tooltip text */
.tooltip .tooltiptext {
  visibility: hidden;
  width: 200px;
  background-color: #bfbfbf;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;

  /* Position the tooltip text */
  position: absolute;
  z-index: 1;
  top:125%;
  right: 0%;
  margin-left: -60px;

  /* Fade in tooltip */
  opacity: 0;
  transition: opacity 0.3s;
  @apply text-midnight-blue bg-white p-6
}
.midnight-blue .title{
  @apply text-yellow-light
}
/* Tooltip arrow */
.tooltip .tooltiptext::before {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #FFF transparent;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}
</style>
