<template>
  <div class="shadow-button z-0" :style="`width: ${width}; height: ${height}; max-width: ${maxWidth};`">
  <button :style="`height: ${height}`" :type="isSubmit ? 'submit' : 'button'" :disabled="disabled" :class="[disabled ? 'disabled' : '',` ${theme ? theme : ''} ${leftAlign ? 'text-left' : ''} ${selected ? 'selected' : ''}  w-full ${status ? status : ''}`]" >
    <span
    :class="[{ 'bg-quest1-button': (image === 'default') }, 'flex flex-nowrap']"
    :style="{ backgroundImage: (image && image !== 'default') && `url(${image})`, 'z-index':'0' }"
    class="w-full h-full text">
      <slot></slot>
      <img v-if="icon" :src="iconfile" alt="icon">
    </span>
  </button>
  </div>
</template>

<script>
export default {
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    isDraggable: {
      type: String,
      default: 'false'
    },
    leftAlign: {
      type: Boolean,
      default: false
    },
    nowrap: {
      type: String,
      default: ''
    },
    selected: {
      type: Boolean,
      default: false
    },
    isSubmit: {
      type: Boolean,
      default: false
    },
    status: {
      type: String,
      default: undefined
    },
    text: {
      type: String,
      default: "Valider"
    },
    width: {
      type: String,
      default: 'auto'
    },
    maxWidth: {
      type: String,
      default: 'none'
    },
    height: {
      type: String,
      default: 'auto'
    },
    maxHeight: {
      type: String,
      default: 'auto'
    },
    icon:{
      type: String,
      default: ""
    },
    action: {
      type: Function,
      default: () => {}
    },
    theme: {
      type:String,
      default: 'default-blue'
    },
    bgColor: {
      type:String,
      default: ''
    },
    image: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      icons: {
        'aide': require('@/assets/icons/icon-aide.svg'),
        'download': require('@/assets/icons/icon-download.png'),
        'download-alt': require('@/assets/icons/icon-download.svg'),
        'down-arrow-midnight': require('@/assets/icons/icon-fleche-bas-midnight.png'),
        'docu-midnight': require('@/assets/icons/ico-bibli-midnight.svg'),
      }
    }
  },
  computed: {
    iconfile() {
      return this.icons[this.icon];
    }
  },
  watch:{
    selected(val){
      this.isSelected = val;
    }
  }
}
</script>

<style lang="scss" scoped>
  button {
    padding:0; margin:0 ;
    @apply text-sm mr-4 mb-3;
  }

  img {
    @apply inline ml-9 mb-1;
  }

  .text {
    padding:0; margin:0;
    @apply pr-4 pl-4 pt-4 pb-4 text-button-sm block;
  }

  .shadow-button {
    & > button {
      @apply relative;
    }

    & > button::before {
      @apply absolute w-full h-full box-border rounded-md border-2 border-current;
      top: 0.38rem;
      left: 0.40rem;

      content: '';
      z-index: 0;
      transition: background-color 150ms linear, color 150ms linear, border 150ms linear;
    }

    & > button:active{
      @apply top-1 left-1 relative;

      &::before {
        @apply top-0 left-0 border-0;
      }
    }

    & > button:hover {
      &::before {
        @apply bg-current;
      }
    }

    & > button > span {
      @apply relative;
      z-index: 1;
    }
  }
  .midnight-blue{
    .text {
      @apply relative bg-midnight-blue border-2 rounded-md  font-press-start;
    }
    
    &::before {
      @apply text-midnight-blue bg-white;
    }

    &:hover {
      &::before {
        @apply bg-midnight-blue;
      }
    }
  }

  .disabled {
    &::before {
      @apply hidden top-0 left-0 border-0;
    }
    @apply top-1 left-1 relative;
  }
  .success{
    @apply bg-green text-white cursor-pointer border-green border-2 shadow-green;
  }
  .danger{
    @apply bg-red text-white cursor-pointer border-red border-2 shadow-red;
  }
  //TODO: SCSS generation

  .default-blue{
    .text {
      @apply text-midnight-blue border-midnight-blue relative bg-white border-2 rounded-md font-press-start;
    }

    &::before {
      @apply bg-white;
    }
  }

  .default-grey {
    @extend .default-blue;

    .text {
      @apply text-light-grey border-light-grey font-coustard-black;
    }

    &::before {
      @apply text-light-grey;
    }

    &.success {
      .text {
        @apply border-0 rounded-xl;
      }

      &::before {
        @apply top-1 left-1 border-0 border-light-grey;
      }


      box-shadow: none;
      @apply bg-white text-white cursor-pointer border-light-grey border-2 rounded-xl;
    }
  }
  .default-midnight{
    @extend .default-blue;

    .text {
      @apply text-blue-grey border-blue-grey font-coustard-black bg-midnight-blue;
    }

    &::before {
      @apply text-blue-grey bg-midnight-blue;
    }

    &.success {
      .text {
        @apply border-0 rounded-xl;
      }

      &::before {
        @apply top-1 left-1 border-0 border-blue-grey;
      }
      box-shadow: none;
      @apply bg-midnight-blue text-blue-grey cursor-pointer border-blue-grey border-2 rounded-xl;
    }
  }
  .light-yellow-default{
    @extend .default-blue;

    .text {
      @apply text-light-grey border-light-grey font-coustard-black;
    }

    &::before {
      @apply text-light-grey;
    }

    &.success{
      .text{
        @apply border-0 rounded-xl;
      }
      &::before {
        @apply top-1 left-1 border-0;
      }
      box-shadow: none;
      @apply bg-white text-white cursor-pointer border-green border-2 rounded-xl;
    }
  }

  .light-grey{
    .text {
      z-index:1;
      @apply border-light-grey  relative bg-white border-2 rounded-md;
    }

    &::before {
      @apply text-light-grey bg-white;
    }
  }

  .light-grey-no-press{
    @apply rounded-md;

    .text {
      @apply border-light-grey  relative bg-white border-2 rounded-md;
    }

    &::before {
      @apply text-light-grey bg-white;
    }
  }

  button.yellow-light{
    .text {
      @apply text-midnight-blue border-yellow-light relative bg-yellow-light border-2 rounded-md font-press-start;
    }

    &::before {
      @apply text-yellow-light bg-midnight-blue;
    }

    &:hover {
      &::before {
        @apply text-midnight-blue bg-midnight-blue;
      }
    }
  }


  .default-blue-reverse{
    .text {
      @apply relative bg-midnight-blue border-2 rounded-md text-white font-press-start;
    }
    
    &::before {
      @apply text-midnight-blue bg-white;
    }

    &:hover {
      &::before {
        @apply bg-midnight-blue;
      }
    }
  }

  button.blue-quest-selector{
    .text {
      @apply  relative border-8 rounded-md text-blue-quest-light font-coustard-black bg-opacity-5 bg-cover p-0;
    }

    &::before {
      @apply text-blue-quest-light;
      top: 0.5rem;
      left: 0.5rem;
    }
  }
  button.yellow-quest-selector{

    .text {
      @apply relative border-8 rounded-md text-yellow-quest-light font-coustard-black bg-opacity-5 bg-cover p-0;
    }

    &::before {
      @apply text-yellow-quest-light;
      top: 0.5rem;
      left: 0.5rem;
    }
  }

  button.pink-quest-selector{
    .text {
      @apply relative border-8 rounded-md text-pink-quest-light font-coustard-black bg-opacity-5 bg-cover p-0;
    }

    &::before {
      @apply text-pink-quest-light;
      top: 0.5rem;
      left: 0.5rem;
    }
  }

  button.green-quest-selector{

    .text {
      @apply relative border-8 rounded-md text-green-quest-light font-coustard-black bg-opacity-5 bg-cover p-0;
    }

    &::before {
      @apply text-green-quest-light;
      top: 0.5rem;
      left: 0.5rem;
    }
  }

  button.yellow-medium-selector{
    .text {
      @apply border-yellow-medium text-midnight-blue-light;
    }

    &::before {
      @apply text-yellow-medium;
      top: 0.5rem;
      left: 0.5rem;
    }
  }

  button.green-selector{
    .text {
      @apply border-green text-midnight-blue-light;
    }

    &::before {
      @apply text-green;
      top: 0.5rem;
      left: 0.5rem;
    }
  }

  .blue-quest-light-yellow {
    @apply text-yellow-light bg-blue-quest-light relative font-press-start rounded-md;

    .text {
      @apply bg-blue-quest-light border-yellow-light border-2 rounded-md;
    }

    &::before {
      @apply text-yellow-light;
    }

    &.disabled {
      @apply border-grey-light text-grey-light opacity-50;
    }
  }

  .green-quest-light-yellow {
    @extend .blue-quest-light-yellow;
    @apply bg-green-quest-light;

    .text {
      @apply bg-green-quest-light;
    }
  }

  .pink-quest-light-yellow {
    @extend .blue-quest-light-yellow;
    @apply bg-pink-quest-light;

    .text {
      @apply bg-pink-quest-light;
    }
  }

  .yellow-quest-light-yellow {
    @extend .blue-quest-light-yellow;
    @apply bg-yellow-quest-light;

    .text {
      @apply bg-yellow-quest-light;
    }
  }
  .midnight-blue-light-yellow {
    @extend .blue-quest-light-yellow;
    @apply bg-midnight-blue;

    .text {
      @apply bg-midnight-blue;
    }
  }
  button.midnight-blue-ultra-light-grey{
    @extend .midnight-blue;
    @apply text-white
  }

  button.blue-quest-ultra-light-grey {
    @extend .blue-quest-light-yellow;
    @apply bg-blue-quest-light;

    &::before {
      @apply text-blue-quest-light;
    }

    &:hover {
      &::before {
        @apply text-blue-quest-dark;
      }
    }

    &.disabled {
      @apply border-grey-ultralight text-grey-light opacity-50;
    }

    .text {
      @apply bg-blue-quest-light border-blue-quest-light;
    }

    &.selected {
      @apply border-blue-quest-light;
    }
  }

  button.green-quest-ultra-light-grey  {
    &::before {
      @apply text-green-quest-light;
    }

    &:hover {
      &::before {
        @apply bg-yellow-light;
      }
    }

    @extend .blue-quest-light-yellow;
    @apply bg-green-quest-light;

    .text {
      @apply bg-green-quest-light border-green-quest-light;
    }
  }

  button.pink-quest-ultra-light-grey  {
    &::before {
      @apply text-pink-quest-light;
    }

    &:hover {
      &::before {
        @apply bg-yellow-light;
      }
    }

    @extend .blue-quest-light-yellow;
    @apply bg-pink-quest-light;

    .text {
      @apply bg-pink-quest-light border-pink-quest-light;
    }
  }

  button.yellow-quest-ultra-light-grey {
    &::before {
      @apply text-yellow-quest-light;
    }

    &:hover {
      &::before {
        @apply bg-yellow-light;
      }
    }

    @extend .blue-quest-light-yellow;
    @apply bg-yellow-quest-light;

    .text {
      @apply bg-yellow-quest-light border-yellow-quest-light;
    }
  }



  .ultra-light-grey-blue-quest {
    &::before {
      @apply text-grey-light;
    }

    &.disabled {
      @apply border-grey-ultralight text-grey-light opacity-50;
    }
    &.selected {
      &::before {
        @apply top-0 left-0 border-0;
      }
      .text {
        @apply border-blue-quest-light;
      }
      @apply top-1 left-1 relative border-blue-quest-light;
    }
    &.cb-error {
      &::before {
        @apply top-0 left-0 border-0;
      }
      .text {
        @apply border-red
      }
      @apply top-1 left-1 relative border-red text-red;

    }
    &.cb-success {
      &::before {
        @apply top-0 left-0 border-0;
      }
      .text {
        @apply border-green
      }
      @apply top-1 left-1 relative border-green text-green;
    }

    @apply text-midnight-blue bg-white border-grey-light relative font-press-start rounded-md;

    .text {
      @apply bg-white border-2 rounded-md border-grey-light;
    }
  }

  button.ultra-light-grey-green-quest  {
    &::before {
      @apply text-green-quest-light;
    }

    &:hover {
      &::before {
        @apply bg-midnight-blue;
      }
    }

    &.selected {
      &::before {
        @apply top-0 left-0 border-0;
      }
      .text {
        @apply border-green-quest-light;
      }
      @apply top-1 left-1 relative border-green-quest-light;
    }
    @extend .ultra-light-grey-blue-quest;
    @apply bg-green-quest-light border-green-quest-light;

    .text {
      @apply bg-green-quest-light border-green-quest-light;
    }
  }

  button.ultra-light-grey-pink-quest  {
    &::before {
      @apply text-pink-quest-light;
    }

    &:hover {
      &::before {
        @apply bg-midnight-blue;
      }
    }

    &.selected {
      &::before {
        @apply top-0 left-0 border-0;
      }
      .text {
        @apply border-pink-quest-light;
      }
      @apply top-1 left-1 relative border-pink-quest-light;
    }
    @extend .ultra-light-grey-blue-quest;
    @apply bg-pink-quest-light border-pink-quest-light;

    .text {
      @apply bg-pink-quest-light border-pink-quest-light;
    }
  }

  button.ultra-light-grey-yellow-quest {
    &::before {
      @apply text-yellow-quest-light;
    }

    &:hover {
      &::before {
        @apply bg-midnight-blue;
      }
    }

    &.selected {
      &::before {
        @apply top-0 left-0 border-0;
      }
      .text {
        @apply border-yellow-quest-light;
      }
      @apply top-1 left-1 relative border-yellow-quest-light;
    }
    @extend .ultra-light-grey-blue-quest;
    @apply bg-yellow-quest-light border-yellow-quest-light;

    .text {
      @apply bg-yellow-quest-light border-yellow-quest-light;
    }
  }




  button.success-light-green  {
    @extend .blue-quest-light-yellow;
    
    &::before {
      @apply text-green;
    }

    &:hover {
      &::before {
        @apply text-md-green;
      }
    }

    &.disabled {
      @apply text-green opacity-100;
      
      .text {
        @apply border-green bg-light-green;
      }
    }
    @apply text-white bg-green border-green relative rounded-md font-press-start;

    .text {
      @apply bg-green border-2 border-green;
    }
  }

  .success-reverse  {
    @extend .light-grey-no-press;

    &::before {
      @apply text-md-green;
    }

    .text{
      @apply border-md-green text-midnight-blue;
    }
    @apply text-light-green bg-white border-md-green;
  }

  .error-reverse  {
    @extend .light-grey-no-press;

    &::before {
      @apply text-md-red;
    }

    .text{
      @apply border-md-red text-midnight-blue;
    }
    @apply text-light-red bg-white border-md-red;
  }

  .default-grey-yesno{
    @extend .default-grey;
    &::before{
      border-radius: 10px;
      border-width: 2px;
    }

    .text{
      border-width: 2px;
      border-radius: 10px;
      @apply text-midnight-blue;
    }
  }

  .success-yesno { 
    @extend .success-reverse;

    .text{
      @apply text-green border-4 border-green
    }
  }

  .error-yesno { 
    @extend .error-reverse;

    .text{
      @apply text-red border-4 border-red
    }
  }

  .error-light-red {
    @extend .blue-quest-light-yellow;
    
    &::before {
      @apply text-red;
    }

    &.disabled {
      @apply border-red bg-md-red text-red opacity-100;
    }
    .no-border {
      @apply border-0;
    }
    @apply text-light-red bg-red;

    .text {
      @apply bg-red;
    }
  }

  .error-red-light-red {
    @extend .blue-quest-light-yellow;
    
    &::before {
      @apply text-red;
    }

    &.disabled {
      @apply border-red bg-red text-light-red opacity-100;
    }
    .no-border {
      @apply border-0;
    }
    @apply text-light-red bg-red;

    .text {
      @apply bg-red border-red;
    }
  }


  .error-dark-red-light-red {
    @extend .blue-quest-light-yellow;

    &::before {
      @apply text-red;
    }

    &.disabled {
      @apply border-none bg-ultra-dark-red text-light-red opacity-100;
    }
    .no-border {
      @apply border-none;
    }
    span{
      @pply border-none;
    }
    @apply text-light-red bg-ultra-dark-red;
    .text {
      @apply bg-ultra-dark-red border-ultra-dark-red text-left;
    }
    &.selection{
      .text{
        text-decoration:none !important;
        @apply no-underline bg-md-red border-red
      }
    }
    @apply border-none
  }

  .success-dark-green-light-green {
    @extend .blue-quest-light-yellow;

    &::before {
      @apply text-green;
    }

    &.disabled {
      @apply border-none bg-ultra-dark-green text-md-green opacity-100;
    }
    .no-border {
      @apply border-none;
    }
    span{
      @pply border-none;
    }
    @apply text-md-green bg-ultra-dark-green;
    .text {
      @apply bg-ultra-dark-green border-ultra-dark-green text-left;
    }
    &.selection{
      .text{
        text-decoration:none !important;
        @apply no-underline bg-md-green  border-green text-green
      }
    }
    @apply border-none
  }

  .light-yellow-blue-quest {
    .text {
      @apply text-blue-quest border-yellow-light relative bg-yellow-light border-2 rounded-md font-press-start;
    }

    &::before {
      @apply text-yellow-light bg-blue-quest-light;
    }

    &:hover {
      &::before {
        @apply text-blue-quest;
      }
    }
  }

  .light-yellow-green-quest {
    @extend .light-yellow-blue-quest;
    .text {
      @apply text-green-quest;
    }
    
    &::before {
      @apply bg-green-quest-light;
    }

    &:hover {
      &::before {
        @apply text-green-quest;
      }
    }
  }

  .light-yellow-pink-quest {
    @extend .light-yellow-blue-quest;
    .text {
      @apply text-pink-quest;
    }

    &::before {
      @apply bg-pink-quest-light;
    }

    &:hover {
      &::before {
        @apply text-pink-quest;
      }
    }
  }

  .light-yellow-yellow-quest {
    @extend .light-yellow-blue-quest;
    .text {
      @apply text-yellow-quest;
    }

    &::before {
      @apply bg-yellow-quest-light;
    }

    &:hover {
      &::before {
        @apply text-yellow-quest;
      }
    }
  }

  button.light-yellow-midnight-blue{
    @extend .light-yellow-blue-quest;
    .text {
      @apply text-midnight-blue;
    }
    &::before {
      @apply bg-midnight-blue;
    }
    &.disabled {
      .text{
        @apply bg-dark-grey border-dark-grey
      }
      &::before {
        transition:none;
        @apply block top-2 left-2 border-dark-grey bg-transparent;
      }
      &:hover {
        &::before {
          @apply border-dark-grey;
        }
        @apply bg-transparent
      }
      @apply border-dark-grey bg-transparent bg-ultra-dark-red text-red top-1 left-1 relative;
    }
    &:hover {
      &::before {
        @apply text-dark-midnight-blue border-yellow-light;
      }
    }
  }

  button.clicked{
    @apply top-1 left-1 relative border-midnight-blue border-2 rounded-xl bg-none;

    .text {
      @apply border-0 rounded-xl;
    }

    &::before{
      transition: unset;
      @apply text-transparent;
      @apply hidden;
    }

    &::hover {
      &::before{
        transition: unset;
      }
    }
  }
</style>
