import {client as apolloClient} from '../config/apolloClient'
import {GameType, MediaFormat} from "../constants/game-config";

const additionalPropAct = {
    duration: '10',
    illustration: '@/assets/pics/acts/illus.png',
    intro: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Accusamus alias cumque distinctio dolore dolores eum ex illo labore molestiae neque nesciunt, omnis quas quibusdam, reiciendis repellat tempora totam vel voluptates.',
    outro: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Accusamus alias cumque distinctio dolore dolores eum ex illo labore molestiae neque nesciunt, omnis quas quibusdam, reiciendis repellat tempora totam vel voluptates.',
}

const additionalPropActivity = {
    score:12,
    illustration: '@/assets/pics/acts/illus.png',
    intro: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Accusamus alias cumque distinctio dolore dolores eum ex illo labore molestiae neque nesciunt, omnis quas quibusdam, reiciendis repellat tempora totam vel voluptates.',
    outro: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Accusamus alias cumque distinctio dolore dolores eum ex illo labore molestiae neque nesciunt, omnis quas quibusdam, reiciendis repellat tempora totam vel voluptates.',
   /* videoExpert: { // Optionnel:  Video de l'expert qui peut, ou pas, débarquer sur le côté
        source: 'assets/mock-video.mp4',
        actor: 'Thomas, l\'expert',
    }*/
}

//contains all activities that can have containers (drad and drop)
const multiActivities= [GameType.DRAGDROP, GameType.DRAGDROPTEXT, ]
const allAnswersInActivities = [GameType.VIDEOSIMPLECARDS, GameType.PHOTO, GameType.YESNO, GameType.VIDEOREPONDEUR, GameType.VIDEOANSWER]
const additionalPropDragAndDrop = {
    value: 'assets/drag_mock.svg'
}
const additionalPropDragAndDropQuestion = {
    label: 'Text a définir en DB',
    instruction: 'Text a définir en DB aussi'
}

async function parseAct(json, moduleId) {
    let actState = {}
    let scenarioState = [];
    Object.assign(actState, json, additionalPropAct)

    for(const scenario of json.scenarios) {
        const [scenarioParsed] = await Promise.all([parseScenario(scenario.scenario, moduleId)])
        scenarioState.push(scenarioParsed)
    }
    actState.scenarios = scenarioState

    return actState
}

async function parseScenarios(scenarios, moduleId) {
    const scenariosState = []
    const activities = {}
    const narratives = {}

    console.log('ParseScenar', scenarios)
    scenarios = scenarios.map(sce => sce.scenario)
    console.log('ParseScenar', scenarios)

    for(const scenario of scenarios) {
        const [scenarioParsed] = await Promise.all([parseScenario(scenario, moduleId)])
        scenariosState.push(scenarioParsed)
    }
    console.log('ScenarioState', scenariosState)
    return scenariosState;
}
async function parseScenario(scenario, moduleId) {

    const scenarioState = {}
    const activities = {}
    const narratives = {}

    Object.assign(scenarioState, scenario)
    console.log('SCENARIO)--->', scenario)
    for (const node of scenario.nodes) {
        // Load nodes content
        console.log("load node")
        switch (node.type.slug) {
            case 'activity': {
                const [activitiesParsed] = await Promise.all([parseActivityFromId(node.node_content, moduleId)])
                activities[node.node_content] = activitiesParsed
                break
            }

            case 'narrative': {
                const [narrativesParsed] = await Promise.all([parseNarrativeFromId(node.node_content, moduleId)])
                narratives[node.node_content] = narrativesParsed
                break
            }
        }
    }

    scenarioState.nodes = scenario.nodes
    scenarioState.activities = activities
    scenarioState.narratives = narratives
    console.log('SCENARIOSTATE)--->', scenarioState)

    return scenarioState;
}
async function getMediaFromId(id){
    return apolloClient.query({
        query: require('@/gql/media.gql'),
        variables: {
            UUID: id,
        }
    })
}
async function parseActivityFromId(id, moduleId = -1){

   let resultQuery = await apolloClient.query({
        query: require('@/gql/activities/activity_by_pk.gql'),
        variables: {
            UUID: id,
        }
    });
    let activity = resultQuery.data.item

    const activityState = Object.assign({}, activity, additionalPropActivity);
    activityState.metas = activity.metas.reduce(
        (dict, meta) => {
            dict[meta.type.slug] = meta.value
            return dict
        }, {})
    const questionsState = [];
    // console.log('Activity raw:',activityState)

    activityState.errorMessage = activity.correction
    activityState.type = activity.type.slug
    activityState.moduleId = moduleId

    const multi = multiActivities.includes(activityState.type)
    const choices = []
    const answers=[[],[]];
    const containers = []
    if(multi){
        const choiceValues = activityState.questions.filter(question => (question.column === null)).reduce((dict, question) => {
            dict[question.line] = (question.media || question.label)

            return dict
        }, {})

        for (const question of activityState.questions) {
            if(question.label !== '' && question.line === null){
                containers.push(question.label)
            }
            for(const choice of question.choices){
                if(choice && choice.is_correct !== undefined) {
                    if(choice.is_correct){
                        answers[question.column].push(choice.id)
                    }
                }
                if(question.line >= 0 && question.column >= 0 && choice.is_correct){
                    choices.push({
                        id: choice.id,
                        value: (choiceValues[question.line] || additionalPropDragAndDrop.value),})
                }
            }
        }

        const questionParsed = {
            containers: containers,
            answers: answers,
            choices: choices,
            label: activity.title,
            instruction: activity.instruction
        }
        Object.assign(questionParsed)
        questionsState.push(questionParsed)
    }
    if(allAnswersInActivities.includes(activityState.type)){
        const questionState = {choices:[], answers: []}
        for (const question of activityState.questions) {

            const choiceState = {}
            if(activityState.type === GameType.VIDEOSIMPLECARDS){
                let mediaUrl = null
                if (question.choices && question.choices.length > 0) {
                    mediaUrl = removeSlash(question.choices[0].media.path)
                    if(mediaUrl.charAt(0) === '/'){
                        mediaUrl = mediaUrl.slice(1)
                    }
                }

                Object.assign(choiceState, {
                    value: question.label,
                    format: parseMediaType(question.type.slug),
                    media: mediaUrl,
                    actor: question?.media?.path ? question.media.path : undefined
                })
            }
            else if(activityState.type === GameType.VIDEOREPONDEUR){
                let mediaUrl = null
                if (question.choices && question.choices.length > 0) {
                    mediaUrl = removeSlash(question.choices[0].media.path)
                    if(mediaUrl.charAt(0) === '/'){
                        mediaUrl = mediaUrl.slice(1)
                    }
                }

                Object.assign(choiceState, {
                    format: parseMediaType(question.type.slug),
                    title: question.label,
                    media: mediaUrl,
                    actor: question.media.path,
                    video: question.choices[0].media.path
                })
            }
            else if(activityState.type === GameType.VIDEOANSWER){
                activityState.actor = {
                    name: activityState.metas.character_name,
                    media: null
                }
                
                if (activityState.metas.character_picture){
                    const [actorPic] = await Promise.all([getMediaFromId(activityState.metas.character_picture)])
                    activityState.actor.media = actorPic.data.item.path
                }

                const video = activityState.media.find(m => m.order===0)
                
                // console.log('MEDIAS', video)

                if (video) {
                    const [introMedia] = await Promise.all([getMediaFromId(video.media.id)])
                    activityState.introMedia = introMedia.data.item
                }
            }
            else if(activityState.type === GameType.PHOTO){
                const pics = (activity.media && activity.media.find(m => m.order == 0))
                
                if (pics) {
                    activityState.pics = pics.media.path
                } else {
                    activityState.pics = ''
                }
                Object.assign(choiceState, {
                    title: question.label,
                    id: question.id,
                    desc: question.errorMessage,
                    action: question.help,
                    media: {
                        format: question.media && await parseMediaType(question.media.type.format),
                        path: question.media && removeSlash(question.media.path)
                    },
                    coords: {
                      y: question.line * 100,
                      x: question.column * 100,
                    }

                })
            }
            else if(activityState.type === GameType.YESNO) {
                Object.assign(choiceState,{
                    id: question.id,
                    value: question.label
                })
                for (let i=0; i<question.choices.length; i++){
                    if(question.choices[i].text === 'Vrai'){
                        Object.assign(choiceState,{
                            answer: question.choices[i].is_correct,
                        })
                    }
                }
            }

            // console.log("Output", activityState)
            questionState.choices.push(choiceState)
            questionState.answers.push(question.id)
            questionsState.push(questionState)
        }
    }else{
        for (const question of activityState.questions) {
            const [questionParsed] = await Promise.all([parseQuestions(activityState.type, Object.assign({}, question))])
            questionParsed.choices = []
            for (const choice of question.choices){
                questionParsed.choices.push({
                    id: choice.id,
                    value: choice.text,
                    media: choice.media ? choice.media : ''

                })
            }
            questionsState.push(questionParsed)
        }
    }

   // activityState.questions = questionsState // Keep questions tree
    activityState.question = questionsState[0]
    activityState.userAnswer = []
    // console.log('Activity state questions:', activityState.question)
    return activityState
}
function parseQuestions(activityType, question){
    const answers = []
        question.choices.forEach( c => {
            if(c.is_correct) {
                answers.push(c.id)
            }
        });
    question.answers = answers
    return question
}

function parseMediaType(type) {
    switch (type) {
        case "message_facetime":
            return MediaFormat.phone
        case "message_visio":
            return MediaFormat.visio
        case "video" :
            return MediaFormat.visio
        case "message_audio":
            return MediaFormat.audio
        case "image":
            return MediaFormat.image
    }
}

async function parseNarrativeFromId(id){
    // console.log('ID IN F', id)
   let resultQuery = await apolloClient.query({
        query: require('@/gql/narratives/narrative_by_pk.gql'),
        variables: {
            UUID: id,
        }
    });

   return parseNarrative(resultQuery.data.item)
}

async function parseNarrative(narrative) {
    if (!narrative) {
        return []
    }
    
   let narratives = []

    for(let n of narrative.slides){
        const narrativeState = {}
        narrativeState.title = n.title
        narrativeState.content = n.text
        narrativeState.type = narrative.type.slug

        const audio = n.metas && n.metas.length > 0 ? n.metas.filter(m => m.type.slug === 'audio') : undefined;
        if(audio){
            const [audioState] = await Promise.all([getMediaFromId(audio[0].value)])
            narrativeState.audio = audioState.data.item.path
        }

        narratives.push(narrativeState)
    }
    return narratives

}
function removeSlash(url) {
    if(url.charAt(0) === '/'){
        url = url.slice(1)
    }
    return url
}
export {parseScenarios,parseAct,parseScenario,parseNarrative, parseActivityFromId,parseNarrativeFromId,getMediaFromId}

