import { createRouter, createWebHistory } from 'vue-router';
import { getState, userHasAccess } from '@/composables/user_log';

const routeOptions = [
  { path: '/help', name: 'Help' },
  { path: '/doc', name: 'Documentation' },
  { 
    path: '/', 
    name: 'Auth',
    beforeEnter(to, from, next){
      if (getState.value?.user_id) {
        next({
          path: '/home',
          name: 'Home',
          params: { fromMain : 'true'},
          replace: true
        });
      } else {
         next()
      }
    }
  },
  { path: '/score', name: 'ScoreOverview', props: true },
  { path: '/enquetechoix', name: 'enquete/EnqueteSelect' },
  { path: '/enqueteintro', name: 'enquete/EnqueteIntro' },
  { path: '/enquete/:noIntro?', name: 'enquete/EnqueteWrapper', props: true },
  { path: '/game', name: 'games/GameWrapper'},
  { path: '/game/:activityId?', name: 'games/GameWrapper'},
  { path: '/congrats', name: 'Congrats' },
  { path: '/home/:bypass?', name: 'Home', props: true},
  { path: '/noAccess', name: 'NoAccess' }
];

const routes = routeOptions.map(route => {
  return {
    ...route,
    component: () => import(`@/views/${route.name}.vue`)
  }
});

const router = createRouter({
    history: createWebHistory(),
    routes, 
});

router.beforeEach((to, from, next) => {
  const toNoAccess = (to.name === 'NoAccess')

  if (!toNoAccess && userHasAccess.value === false) {
    next({ name: 'NoAccess' })
    return
  } else if (toNoAccess && userHasAccess.value === true) {
    next({ name: 'Auth' })
    return
  }

  next()
})

export default router

