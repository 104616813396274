<template>
  <div id="myModal" class="modal z-3 bg-black bg-opacity-50" ref="modal-content" >
      <div class="modal-content w-3/12 z-3  rounded-lg ">
        <div class="container">
          <h1 class="text-2xl mb-5">Se déconnecter</h1>
          <p>Êtes-vous sur de vouloir vous déconnecter?</p>
          <div class="flex bg-transparent justify-center gap-10 mt-10">
            <a class="cursor-pointer" @click="yes()">Oui</a>
            <a class="cursor-pointer" @click="cancel()">Non</a>
          </div>
        </div>
      </div>
  </div>
</template>

<script>
export default {
  emits: ["action"],
  methods: {
    openModal() {
      this.$refs["modal-content"].style.display="block";
    },
    cancel() {
      this.$emit('action', 'cancel')
      this.$refs["modal-content"].style.display="none";
    },
    yes() {
      this.$refs["modal-content"].style.display="none";
      this.$emit('action', 'disconnect')
    }
  },
  props: {
    open: {
      type: Boolean,
      default: false
    },
    bgImage: {
      type: String,
      default: 'false'
    },
    bgColor: {
      type: String,
      default: 'false'
    },
    width: {
      type: String,
      default: 'auto'
    }
  },
  watch: {
    open(val) {
     if(val) {
       this.openModal()
     }
    }
  },
}
</script>

<style lang="scss" scoped>
/* Add padding and center-align text to the container */
.container {
  padding: 16px;
  text-align: center;
}

/* The Modal (background) */
.modal {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  color:black;
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  padding-top: 50px;
}

/* Modal Content/Box */
.modal-content {

  background-color: #fefefe;
  margin: 5% auto 15% auto; /* 5% from the top, 15% from the bottom and centered */
  border: 1px solid #888;
}
/* Style the horizontal ruler */
hr {
  border: 1px solid #f1f1f1;
  margin-bottom: 25px;
}

/* Clear floats */
.clearfix::after {
  content: "";
  clear: both;
  display: table;
}

/* Change styles for cancel button and delete button on extra small screens */
@media screen and (max-width: 300px) {
  .cancelbtn, .deletebtn {
    width: 100%;
  }
}
</style>
