import {createApp, h, provide} from 'vue'
import App from './App.vue'
import store from './store'

import { DefaultApolloClient } from '@vue/apollo-composable'
import { client } from './config/apolloClient'
import router from './config/router'
import text from './config/text'
import {useAuth} from "@/composables/auth"
let { hasValidToken } = useAuth()

const init = async () => {
  try {
    return await hasValidToken();
  } catch {
    return false
  }
}
import './index.scss'

import Nl2br from "./components/Nl2br";
import ShadowButton from "./components/ShadowButton";
import SimpleModal from "./components/SimpleModal";
import ScoreOverview from "./views/ScoreOverview";
import ScoreBox from "./components/score-box/ScoreBox";
import SkillsProgressBar from "./components/score-box/SkillsProgressBar";
import { setUser } from '@/composables/user_log';
import { initLogTypes } from './composables/log_type'
import { initUserLog } from './composables/user_log'


init().then(async(user) => {
  // Clear store cache when login with auth
  if (window.location.hash && window.location.hash.includes('token')) {
    store.dispatch('gamestatus/resetAll')
  }

  let app = createApp({
    setup () {
      provide(DefaultApolloClient, client)
    },
    render: () => h(App),
  })
  app.config.globalProperties.$t = text
  app.provide('$t', text)
  app.use(router)
  app.use(store)
  app.component('ShadowButton', ShadowButton)
  app.component('SimpleModal', SimpleModal)
  app.component('ScoreOverview', ScoreOverview)
  app.component('SkillsProgressBar', SkillsProgressBar)
  app.component('ScoreBox', ScoreBox)
  app.component('nl2br', Nl2br)
 
  if( !user) {
    // push to log path 
    app.config.globalProperties.$router.push('/')
    app.mount('#app')
  } else {
    setUser(user);
    await initLogTypes();
    await initUserLog();
    app.mount('#app')
  }
});

