import {ScoreType} from "../../constants/game-config";
import {getState} from "../../composables/user_log";
import {getScoreType} from "../../composables/log_type";


export default {
    namespaced:true,
    state: {
        currentScenario: undefined,
        currentSequence: undefined,
        currentCourseType: undefined,
        currentModule: undefined,
        currentRattrapageDone: false,
        introNiv1Done: false,
        introNiv2Done: false,
        missionFinished: [],
        questFinished: [],
        actsFinished: [],
        activitiesFinished:[],
        scenariosFinished: [],
        scenariosPending: [],
        actActorScore: 0,
        actQualityScore: 0,
        actLawScore: 0,
        localActorScore: 0,
        localQualityScore: 0,
        localLawScore: 0,
    },
    actions:{
        async setScoreForAct({commit}, {scenarios}){
            //commit('resetAllScores')
            const userActivities = getState.value.activities
            const activities  = scenarios.reduce((actFiltered, val) =>  {
                for( const k of Object.keys(val.activities)){
                    const act = userActivities.filter(a => {
                        return a.data_key === k
                    })
                    if(act.length > 0){
                        actFiltered.push(act[0])
                    }
                }
                return actFiltered
            },[])
            for(const activity of activities) {
                if(activity.data?.score){
                    if (activity.data.score_type === getScoreType('quality').id) {
                        commit('addQualityScore',  activity.data.score)
                    }
                    if (activity.data.score_type === getScoreType('legal').id) {
                        commit('addLawScore',  activity.data.score)
                    }
                    if (activity.data.score_type === getScoreType('actor').id) {
                        commit('addActorScore',  activity.data.score)
                    }
                }
            }
        },
        async setGame({commit}, {currentCourseType, currentSequence, currentModule}){
            commit('changeCurrentCourseType', currentCourseType)
            commit('changeCurrentSequence', currentSequence)
            commit('changeCurrentModule', currentModule)
        },
        async setCurrentScenario({commit}, scenario_id){
            commit('changeCurrentScenario', scenario_id)
        },
        async setIntroNiv1Done({commit}, status){
            commit('changeIntroNiv1Done', status)
        },
        async setIntroNiv2Done({commit}, status){
            commit('changeIntroNiv2Done', status)
        },
        async setCurrentRattrapageDone({commit}, done){
            commit('changeCurrentRattrapageStatus', done)
        },
        async setCurrentSequence({commit}, sequence_id){
            commit('changeCurrentSequence', sequence_id)
        },
        async setCurrentModule({commit}, module_id){
            commit('changeCurrentModule', module_id)
        },
        async setCurrentCourseType({commit}, course_type_slug){
            commit('changeCurrentCourseType', course_type_slug)
        },
        async addScenarioPending({commit, state}, scenario_id){
            if(!state.scenariosPending.includes(scenario_id)){
                commit('appendScenarioPending', scenario_id)
            }
        },
        async removeScenarioPending({commit, state}, scenario_id){
            if(!state.scenariosPending.includes(scenario_id)){
                commit('deleteScenarioPending', scenario_id)
            }
        },
        async addScenarioFinished({commit, state}, scenario_id){
            if(!state.scenariosFinished.includes(scenario_id)){
                commit('appendScenarioFinish', scenario_id)
            }
        },
        async addActFinished({commit, state}, act_id){
            if(!state.actsFinished.includes(act_id)){
                commit('appendActFinish', act_id)
            }
        },
        async addMissionFinished({commit, state}, mission_id){
            if(!state.missionFinished.includes(mission_id)){
                commit('appendMissionFinish', mission_id)
            }
        },
        async addQuestFinished({commit, state}, quest_id){
            if (!state.questFinished.includes(quest_id) && quest_id !== undefined){
                commit('appendQuestFinish', quest_id)
            }
        },
        async addScoreTo({commit}, {scoreType, points}){
            switch (scoreType){
                case ScoreType.ACTOR:
                    commit('addActorScore',  points)
                    break
                case ScoreType.LAW:
                    commit('addLawScore', points)
                    break
                case ScoreType.LEGAL:
                    commit('addLawScore', points)
                    break
                case ScoreType.QUALITY:
                    commit('addQualityScore',  points)
                    break
            }
        },
        async resetLocalScores({commit}) {
          commit('resetAllLocalScores')
        },
        addActivitiesFinished({commit}, activity_id){
            commit('appendActivitiesFinish', activity_id)
        },
        async removeActivitiesFinished({commit}, activity_id){
            if(!this.state.activitiesFinished.includes(activity_id)){
                commit('deleteActivitiesFinish', activity_id)
            }
        },
        async resetAll({commit}){
            commit('resetAll')
        }
    },
    mutations:{
        resetAll(state) {
            state.actActorScore = 0
            state.actLawScore = 0
            state.actQualityScore = 0
            state.localLawScore = 0
            state.localActorScore = 0
            state.localQualityScore = 0
            state.currentScenario= undefined
            state.currentSequence= undefined
            state.currentCourseType= undefined
            state.currentModule= undefined
            state.missionFinished= []
            state.questFinished= []
            state.actsFinished= []
            state.activitiesFinished=[]
            state.scenariosFinished= []
            state.scenariosPending= []
        },
        resetAllScores(state) {
            state.actActorScore = 0
            state.actLawScore = 0
            state.actQualityScore = 0
        },
        addActorScore(state, points) {
            state.localActorScore += points;
            state.actActorScore += points
        },
        addQualityScore(state, points) {
            state.localQualityScore += points;
            state.actQualityScore += points
        },
        addLawScore(state, points) {
            state.localLawScore += points;
            state.actLawScore += points
        },
        resetAllLocalScores(state) {
          state.localActorScore = state.localLawScore = state.localQualityScore = 0
        },
        changeCurrentScenario(state, scenario_id) {
            state.currentScenario = scenario_id
        },
        changeCurrentSequence(state, sequence_id) {
            state.currentSequence = sequence_id
        },
        changeCurrentModule(state, module_id) {
            state.currentModule = module_id
        },
        changeIntroNiv1Done(state, status) {
            state.introNiv1Done = status
        },
        changeIntroNiv2Done(state, status) {
            state.introNiv2Done = status
        },
        changeCurrentCourseType(state, course_type_slug) {
            state.currentCourseType = course_type_slug
        },

        appendScenarioPending(state, scenario_id) {
            state.scenariosPending.push(scenario_id)
        },
        deleteScenarioPending(state, scenario_id) {
            state.scenariosPending.splice(this.scenariosPending.indexOf(scenario_id), 1)
        },
        appendScenarioFinish(state, scenario_id) {
            state.scenariosFinished.push(scenario_id)
        },
        changeCurrentRattrapageStatus(state, status) {
            state.currentRattrapageDone = status
        },
        appendActFinish(state, act_id) {
            state.actsFinished.push(act_id)
        },
        appendMissionFinish(state, mission_id) {
            state.missionFinished.push(mission_id)
        },
        appendQuestFinish(state, quest_id){
            state.questFinished.push(quest_id)
        },
        appendActivitiesFinish(state, activity_id) {
            if(!state.activitiesFinished.includes(activity_id)){
                state.activitiesFinished.push(activity_id)
            }
        },
        deleteActivityFinish(state, activity_id) {
            state.activitiesFinished.splice(this.activitiesFinished.indexOf(activity_id), 1)
        },
    }
}
