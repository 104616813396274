import {GameType} from '../../constants/game-config'
import {client as apolloClient} from '../../config/apolloClient'
import {parseAct, getMediaFromId, parseNarrativeFromId} from "../../utils/apollo-parser";

export default {
    namespaced: true,
    state: {
        'fakeAct': {

        },
        'enquete': {
            title: 'Incendie de friteuse',
            'thumbAndBgPic': 'assets/pics/buildings.jpg',
            'cover': '',
            'illusAct': 'assets/pics/act/right-pic.png',
            'lawPointsRequired': 20,
            'qualityPointsRequired': 20,
            'actorPointsRequired': 20,
            'narrativeIntro': [
                {
                    title: 'Blablabla slide 1',
                    content: '1Blablabla slide 1 content consectetur adipisicing elit. Accusamus alias cumque distinctio dolore dolores eum ex illo labore molestiae neque nesciunt, omnis quas quibusdam, r',
                    audio: 'assets/mock-audio.wav'
                },
                {
                    title: '2Blablabla slide 2',
                    content: 'Blablabla slide 2 content consectetur adipisicing elit. Accusamus alias cumque distinctio dolore dolores eum ex illo labore molestiae neque nesciunt, omnis quas quibusdam, r',
                    audio: 'assets/mock-audio.wav'
                },
            ],
            'narrativeOutro': [
                {
                    title: '3Blablabla slide 1',
                    content: 'Blablabla slide 1 content consectetur adipisicing elit. Accusamus alias cumque distinctio dolore dolores eum ex illo labore molestiae neque nesciunt, omnis quas quibusdam, r',
                    audio: 'assets/mock-audio.wav'
                },
                {
                    title: '4Blablabla slide 2',
                    content: 'Blablabla slide 2 content consectetur adipisicing elit. Accusamus alias cumque distinctio dolore dolores eum ex illo labore molestiae neque nesciunt, omnis quas quibusdam, r',
                    audio: 'assets/mock-audio.wav'
                },
            ],
            acts: [/*{
                id: 34,
                title: 'questionsnez les protagonistes',
                duration: '10',
                illustration: '@/assets/pics/acts/illus.png',
                intro: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Accusamus alias cumque distinctio dolore dolores eum ex illo labore molestiae neque nesciunt, omnis quas quibusdam, reiciendis repellat tempora totam vel voluptates.',
                outro: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Accusamus alias cumque distinctio dolore dolores eum ex illo labore molestiae neque nesciunt, omnis quas quibusdam, reiciendis repellat tempora totam vel voluptates.',
                scenarios: [
                    {
                        name: 'Interroger les collègues de l\'agence',
                        id: 4,
                        activities: [
                            {
                                type: GameType.YESNO,
                                id:951,
                                score:12,
                                successMessage:'+10 point en qualité services',
                                videoExpert: {
                                    source: 'assets/mock-video.mp4',
                                    actor: 'Thomas, l\'expert',
                                } ,
                                errorMessage:'+10 point en qualité services',
                                question: {
                                    'label': 'blablabla consequuntur, debitis deleniti et, fuga harum illu',
                                    'answers': [1, 2],
                                    'choices': [
                                        {id: 1, value: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aliquid amet consequatur consequuntur, debitis deleniti et, fuga harum illu',
                                            answer: true},
                                        {id: 2, value: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aliquid amet consequatur consequuntur, debitis deleniti et, fuga harum illu vervev', answer: false},
                                    ],
                                },
                                userAnswer: []
                            },
                            {
                                type: GameType.QCM,
                                id:1233333,
                                score:12,
                                successMessage:'+10 point en qualité services',
                                videoExpert: {
                                    source: 'assets/mock-video.mp4',
                                    actor: 'Thomas, l\'expert',
                                } ,
                                errorMessage:'+10 point en qualité services',

                               question: {
                                    'label': 'Bonne reponse: 2 et 4 | ipsum dolor sit amet, consectetur adipisicing elit. Aliquid amet consequatur consequuntur, debitis deleniti et, fuga harum illu',
                                    'answers': [2, 4],
                                    'choices': [
                                        {id: 1, value: 'lorem blablabla'},
                                        {id: 2, value: 'lorem blablabla'},
                                        {id: 3, value: 'lorem blablabla'},
                                        {id: 4, value: 'lorem blablabla'},
                                    ],
                                },
                                userAnswer: []
                            },
                            {
                                type: GameType.PHOTO,
                                id:649,
                                question: {
                                    'label': 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aliquid amet consequatur consequuntur, debitis deleniti et, fuga harum illu',
                                    'answers': [1,2,3,4],
                                    'pics': 'assets/pics/quest/cuisine_full_2.png',
                                    'choices': [
                                        {
                                            id: 1,
                                            title: 'La fenêtre',
                                            desc: 'Ouvrant, dormant et vitrages endommagés',
                                            action: 'Remplacement à l\'identique: 3000€',
                                            media:{
                                                format:'pic',
                                                path:'assets/pics/quest/window.png'
                                            } ,
                                            coords: {
                                                x: 10,
                                                y: 10
                                            }
                                        },
                                        {
                                            id: 2,
                                            title: 'La hote',
                                            desc: 'Ouvrant, dormant et vitrages endommagés',
                                            action: 'Remplacement à l\'identique: 3000€',
                                            media:{
                                                format:'pic',
                                                path:'assets/pics/quest/window.png'
                                            } ,
                                            coords: {
                                                x: 14,
                                                y: 44
                                            }
                                        },
                                        {
                                            id: 3,
                                            title: 'La machine',
                                            desc: 'Ouvrant, dormant et vitrages endommagés',
                                            media:{
                                                format:'visio',
                                                path:'assets/mock-video.mp4'
                                            } ,
                                            action: 'Remplacement à l\'identique: 3000€',                                                    pic: 'assets/pics/quest/window.png',
                                            coords: {
                                                x: 60,
                                                y: 60
                                            }
                                        },
                                        {
                                            id: 4,
                                            title: 'La machine a pop corn',
                                            desc: 'Ouvrant, dormant et vitrages endommagés',
                                            media:{
                                                format:'audio',
                                                path:'assets/mock-audio.wav'
                                            } ,
                                            action: 'Remplacement à l\'identique: 3000€',                                                    pic: 'assets/pics/quest/window.png',
                                            coords: {
                                                x: 80,
                                                y: 40
                                            }
                                        },
                                    ],
                                },
                                userAnswer: []
                            },
                                    {
                                        type: GameType.DRAGDROPTEXT,
                                        id:669,
                                        question: {
                                            'label': 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aliquid amet consequatur ',
                                            'containers': ['Bailleur', 'Syndic'],
                                            'answers': [[1,3,2],[4,6,5]],
                                            'choices': [
                                                {id: 1, value: 'text 1'},
                                                {id: 2, value: 'text 2'},
                                                {id: 3, value: 'encore un text'},
                                                {id: 4, value: 'toujours du text'},
                                                {id: 5, value: 'un text'},
                                                {id: 6, value: 'un text'},
                                            ],
                                        },
                                        userAnswer: []
                                    },
                                    {
                                        type: GameType.DRAGDROP,
                                        id:587,
                                        question: {
                                            'label': 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aliquid amet consequatur ',
                                            'containers': ['parties public', 'parties privatves'],
                                            'answers': [[1,2],[3]],
                                            'choices': [
                                                {id: 1, value: 'assets/drag_mock.svg'},
                                                {id: 2, value: 'assets/drag_mock.svg'},
                                                {id: 3, value: 'assets/drag_mock.svg'},
                                            ],
                                        },
                                        userAnswer: []
                                    },
                                    {
                                        type: GameType.ACTORS,
                                        id:187,
                                        videoExpert: {
                                            source: 'assets/mock-video.mp4',
                                            actor: 'Thomas, l\'expert',
                                        } ,
                                        question: {
                                            'label': '2dzdz1 Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aliquid amet consequatur consequuntur, debitis deleniti et, fuga harum illu',
                                            'answers': [2,4,5],
                                            'choices': [
                                                {id: 1, value: 'lorem blablabla'},
                                                {id: 2, value: 'lorem blablabla'},
                                                {id: 3, value: 'lorem blablabla'},
                                                {id: 4, value: 'lorem blablabla'},
                                                {id: 5, value: 'lorem blablabla'},
                                                {id: 6, value: 'lorem blablabla'},
                                                {id: 7, value: 'lorem blablabla'},
                                                {id: 8, value: 'lorem blablabla'},
                                                {id: 9, value: 'lorem blablabla'},
                                                {id: 10, value: 'lorem blablabla'},
                                            ],
                                        },
                                        userAnswer: []
                                    },

                                    {
                                        type: GameType.NARRATIVE,
                                        id:143,
                                        content: 'Narration no sound',
                                    },
                                    {
                                        type:  GameType.QCM,
                                        id:14,
                                        question: {
                                            'label': '21 Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aliquid amet consequatur consequuntur, debitis deleniti et, fuga harum illu',
                                            'type': 'single',
                                            'answers': [2],
                                            'choices': [
                                                {id: 1, value: 'lorem blablabla'},
                                                {id: 2, value: 'lorem blablabla'},
                                                {id: 3, value: 'lorem blablabla'},
                                                {id: 4, value: 'lorem blablabla'},
                                            ],
                                        },
                                        userAnswer: []
                                    },
                            {
                                type: GameType.VIDEODEROULEMENTLOCK,
                                id: 4022272,
                                question: {
                                    'label': '2dzdz1 Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aliquid amet consequatur consequuntur, debitis deleniti et, fuga harum illu',
                                    'answers': [1],
                                    id: 1,
                                    value: 'Jonathan responsable du site',
                                    video: 'assets/mock-video.mp4',
                                    items: [
                                        {
                                            'label': 'Posez ces 3 questionss à Madame Gomez',
                                            actor: 'Mme Gomez, NRV',
                                            choices: [
                                                {id: 1, value: '“Avez-vous eu un premier rendez-vous d’expertise de la part de votre assurance ?”', video: 'assets/mock-video.mp4'},
                                                {id: 3, value: '“Quelles démarches avez-vous effectuées ?”', video: 'assets/mock-video.mp4', format:'phone'},
                                                {id: 2, value: '“Avez-vous eu un premier rendez-vous d’expertise de la part de votre assurance ?”\n', video: 'assets/mock-video.mp4'},
                                            ]
                                        },
                                    ]
                                },
                                userAnswer: []
                            },
                            {
                                type: GameType.VIDEOANSWER,
                                id: 45272,
                                question: {
                                    'label': '2dzdz1 Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aliquid amet consequatur consequuntur, debitis deleniti et, fuga harum illu',
                                    'answers': [1],
                                    id: 1,
                                    value: 'Jonathan responsable du site',
                                    items: [
                                        {
                                            answers: [3],
                                            'label': 'Que répondez vous au syndic',
                                            video: 'assets/mock-video.mp4',
                                            goodAnswerMedia: 'assets/mock-video.mp4',
                                            wrongAnswerMedia: 'assets/mock-video.mp4',
                                            actor: 'Marie Stusavais',
                                            choices: [
                                                {id: 1, value: 'Merci j\'en prend notes'},
                                                {id: 3, value: 'Bonne reponse'},
                                                {id: 2, value: 'Je vais voir avec mes collègues'},
                                            ]
                                        },
                                        {
                                            answers: [2],
                                            'label': 'Que répondez vous au syndic',
                                            video: 'assets/mock-video.mp4',
                                            goodAnswerMedia: 'assets/mock-video.mp4',
                                            wrongAnswerMedia: 'assets/mock-video.mp4',
                                            actor: 'Marie Stusavais',
                                            choices: [
                                                {id: 1, value: 'Merci j\'en prend notes aussi'},
                                                {id: 3, value: 'Et sinon?'},
                                                {id: 2, value: 'Bonne reponse'},
                                            ]
                                        },
                                    ]
                                },
                                userAnswer: []
                            },
                            {
                                type: GameType.VIDEOINTERACTIVE,
                                id: 6688,
                                question: {
                                    'label': '2dzdz1 Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aliquid amet consequatur consequuntur, debitis deleniti et, fuga harum illu',
                                    'answers': [1],
                                    id: 1,
                                    value: 'Jonathan responsable du site',
                                    items: [
                                        {
                                            answers: [3],
                                            'label': 'Que répondez vous au syndic',
                                            video: 'assets/mock-video.mp4',
                                            actor: 'Marie Stusavais',
                                            choices: [
                                                {id: 1, value: 'Merci j\'en prend notes'},
                                                {id: 3, value: 'Bonne reponse'},
                                                {id: 2, value: 'Je vais voir avec mes collègues'},
                                            ]
                                        },
                                        {
                                            answers: [2],
                                            'label': 'Que répondez vous au syndic',
                                            video: 'assets/mock-video.mp4',
                                            actor: 'Melanie Melanie',
                                            choices: [
                                                {id: 1, value: 'Merci j\'en prend notes aussi'},
                                                {id: 3, value: 'Et sinon?'},
                                                {id: 2, value: 'Bonne reponse'},
                                            ]
                                        },
                                    ]
                                },
                                userAnswer: []
                            },
                            {
                                type: GameType.VIDEOSIMPLECARDS,
                                id:2222,
                                question: {
                                    'label': '2dzdz1 Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aliquid amet consequatur consequuntur, debitis deleniti et, fuga harum illu',
                                    'answers': [1,2,3],
                                    'choices': [
                                        {id: 3, value: 'Roger responsable du site', actor:'assets/visage.svg', media: 'assets/mock-video.mp4', format:'visio'},
                                        {id: 1, value: 'Jonathan responsable du site',actor:'assets/visage.svg', media: 'assets/mock-video.mp4', format:'phone'},
                                        {id: 2, value: 'Michel responsable du site', actor:'assets/visage.svg', media: 'assets/mock-audio.wav', format:'audio'},
                                    ],
                                },
                                userAnswer: []
                            },
                                ],
                            }
                        ]
                    },*/

                // {
                //     title: 'Mangez des fruits',
                //     id: 4,
                //     duration: '15',
                //     illustration: '@/assets/pics/acts/illus.png',
                //     intro: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Accusamus alias cumque distinctio dolore dolores eum ex illo labore molestiae neque nesciunt, omnis quas quibusdam, reiciendis repellat tempora totam vel voluptates.',
                //     outro: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Accusamus alias cumque distinctio dolore dolores eum ex illo labore molestiae neque nesciunt, omnis quas quibusdam, reiciendis repellat tempora totam vel voluptates.',
                //     scenarios: [
                //         {
                //             title: 'Magez des fruits',
                //             id: 44,
                //             activities: [
                //                 {
                //                     type: GameType.YESNO,
                //                     id: 951,
                //                     score: 12,
                //                     successMessage: '+10 point en qualité services',
                //                     /* videoExpert: {
                //                          source: 'assets/mock-video.mp4',
                //                          actor: 'Thomas, l\'expert',
                //                      },*/
                //                     errorMessage: '+10 point en qualité services',
                //                     question: {
                //                         'label': 'blablabla consequuntur, debitis deleniti et, fuga harum illu',
                //                         'answers': [1, 2],
                //                         'choices': [
                //                             {
                //                                 id: 1,
                //                                 value: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aliquid amet consequatur consequuntur, debitis deleniti et, fuga harum illu',
                //                                 answer: true
                //                             },
                //                             {
                //                                 id: 2,
                //                                 value: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aliquid amet consequatur consequuntur, debitis deleniti et, fuga harum illu vervev',
                //                                 answer: false
                //                             },
                //                         ],
                //                     },
                //                     userAnswer: []
                //                 },
                //                 {
                //                     type: GameType.QCM,
                //                     id: 1233333,
                //                     score: 12,
                //                     successMessage: '+10 point en qualité services',
                //                     /*videoExpert: {
                //                         source: 'assets/mock-video.mp4',
                //                         actor: 'Thomas, l\'expert',
                //                     },*/
                //                     errorMessage: '+10 point en qualité services',

                //                     question: {
                //                         'label': 'Bonne reponse: 2 et 4 | ipsum dolor sit amet, consectetur adipisicing elit. Aliquid amet consequatur consequuntur, debitis deleniti et, fuga harum illu',
                //                         'answers': [2, 4],
                //                         'choices': [
                //                             {id: 1, value: 'lorem blablabla'},
                //                             {id: 2, value: 'lorem blablabla'},
                //                             {id: 3, value: 'lorem blablabla'},
                //                             {id: 4, value: 'lorem blablabla'},
                //                         ],
                //                     },
                //                     userAnswer: []
                //                 },
                //             ]
                //         },
                //         {
                //             title: 'Mangez des légumes',
                //             id: 45,
                //             activities: [
                //                 {
                //                     type: GameType.YESNO,
                //                     id: 9521,
                //                     score: 12,
                //                     successMessage: '+10 point en qualité services',
                //                     /*  videoExpert: {
                //                            source: 'assets/mock-video.mp4',
                //                            actor: 'Thomas, l\'expert',
                //                        },*/
                //                     errorMessage: '+10 point en qualité services',
                //                     question: {
                //                         'label': 'blablabla consequuntur, debitis deleniti et, fuga harum illu',
                //                         'answers': [1, 2],
                //                         'choices': [
                //                             {
                //                                 id: 1,
                //                                 value: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aliquid amet consequatur consequuntur, debitis deleniti et, fuga harum illu',
                //                                 answer: true
                //                             },
                //                             {
                //                                 id: 2,
                //                                 value: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aliquid amet consequatur consequuntur, debitis deleniti et, fuga harum illu vervev',
                //                                 answer: false
                //                             },
                //                         ],
                //                     },
                //                     userAnswer: []
                //                 },
                //                 {
                //                     type: GameType.QCM,
                //                     id: 12233333,
                //                     score: 12,
                //                     successMessage: '+10 point en qualité services',
                //                     /*videoExpert: {
                //                          source: 'assets/mock-video.mp4',
                //                          actor: 'Thomas, l\'expert',
                //                      },*/
                //                     errorMessage: '+10 point en qualité services',

                //                     question: {
                //                         'label': 'Bonne reponse: 2 et 4 | ipsum dolor sit amet, consectetur adipisicing elit. Aliquid amet consequatur consequuntur, debitis deleniti et, fuga harum illu',
                //                         'answers': [2, 4],
                //                         'choices': [
                //                             {id: 1, value: 'lorem blablabla'},
                //                             {id: 2, value: 'lorem blablabla'},
                //                             {id: 3, value: 'lorem blablabla'},
                //                             {id: 4, value: 'lorem blablabla'},
                //                         ],
                //                     },
                //                     userAnswer: []
                //                 },
                //             ]
                //         },
                //         {
                //             title: 'Ecoutez du Death Metal',
                //             id: 47,
                //             activities: [
                //                 {
                //                     type: GameType.YESNO,
                //                     id: 9511,
                //                     score: 12,
                //                     successMessage: '+10 point en qualité services',
                //                     /* videoExpert: {
                //                          source: 'assets/mock-video.mp4',
                //                          actor: 'Thomas, l\'expert',
                //                      },*/
                //                     errorMessage: '+10 point en qualité services',
                //                     question: {
                //                         'label': 'blablabla consequuntur, debitis deleniti et, fuga harum illu',
                //                         'answers': [1, 2],
                //                         'choices': [
                //                             {
                //                                 id: 1,
                //                                 value: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aliquid amet consequatur consequuntur, debitis deleniti et, fuga harum illu',
                //                                 answer: true
                //                             },
                //                             {
                //                                 id: 2,
                //                                 value: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aliquid amet consequatur consequuntur, debitis deleniti et, fuga harum illu vervev',
                //                                 answer: false
                //                             },
                //                         ],
                //                     },
                //                     userAnswer: []
                //                 },
                //                 {
                //                     type: GameType.QCM,
                //                     id: 12333313,
                //                     score: 12,
                //                     successMessage: '+10 point en qualité services',
                //                     /* videoExpert: {
                //                          source: 'assets/mock-video.mp4',
                //                          actor: 'Thomas, l\'expert',
                //                      },*/
                //                     errorMessage: '+10 point en qualité services',
                //                     question: {
                //                         'label': 'Bonne reponse: 2 et 4 | ipsum dolor sit amet, consectetur adipisicing elit. Aliquid amet consequatur consequuntur, debitis deleniti et, fuga harum illu',
                //                         'answers': [2, 4],
                //                         'choices': [
                //                             {id: 1, value: 'lorem blablabla'},
                //                             {id: 2, value: 'lorem blablabla'},
                //                             {id: 3, value: 'lorem blablabla'},
                //                             {id: 4, value: 'lorem blablabla'},
                //                         ],
                //                     },
                //                     userAnswer: []
                //                 },
                //             ],
                //         }
                //     ]
                // }
            ]
        }
    },
    actions:{
        async setUserAnswer({commit},payload){
            if(payload.valuestate.checked){
                if(payload.valuestate.reset) {
                    commit('replaceAnswers', payload)
                }else{
                    commit('addAnswer', payload)
                }
            }
            else{
                commit('removeAnswer', payload)
            }
        },
        async resetUserAnswers({commit},payload){
            commit('resetAnswers', payload)
        },
        async setEnquete({commit, rootState}, id){
            const enquete = rootState.course.courses[0].modules.find( m => m.id === id)
            console.log('ENQUETE', enquete)
            let title = enquete.title
            let cover = await getMediaFromId(enquete.metas.cover)
            let illustration = await getMediaFromId(enquete.metas.illustration)
            let narrativeIntro =  enquete.metas.narrator_intro ? await parseNarrativeFromId(enquete.metas.narrator_intro) : undefined
            let narrativeOutro =  enquete.metas.narrator_outro ? await parseNarrativeFromId(enquete.metas.narrator_outro) : undefined
            let thumbnail =  await getMediaFromId(enquete.metas.thumbnail)

            commit('setEnqueteData', { cover, illustration, thumbnail, narrativeIntro, narrativeOutro, title})
        },
        getSequence({commit}, {id, moduleId}){
           return apolloClient.query({
               query: require('@/gql/sequences/find_one.gql'),
               variables: {
                   UUID: id,
               }
           }).then(async resultQuery => {
               const [actParsed] = await Promise.all([parseAct(resultQuery.data.item, moduleId)])
               if (Array.isArray(actParsed.metas)) {
                   actParsed.metas = actParsed.metas.reduce((dict, meta) => {
                       dict[meta.type.slug] = meta.value
                       return dict
                   }, {})
               }else{
                   actParsed.metas = {}
               }
               commit('addAct', actParsed)
           })
        },
        getModule({commit}, id){
            return apolloClient.query({
                query: require('@/gql/modules/find_one_mission.gql'),
                variables: {
                    UUID: id,
                }
            }).then(async resultQuery => {
                const [actParsed] = await Promise.all([parseAct(resultQuery.data.item, id)])
                commit('addAct', actParsed)
            })
        },
        // replaceByScenarioDb({commit}, id){
        //     return apolloClient.query({
        //         query: require('@/gql/scenarios/find_one.gql'),
        //         variables: {
        //             UUID: id,
        //         }
        //     }).then(async resultQuery => {
        //         const [scenarioParser] = await Promise.all([parseScenario(resultQuery.data.item)])
        //         commit('replaceScenarioAct', scenarioParser)
        //     })
        // },
        setCurrentSequenceId({commit}, sequence_id) {
            commit('SET_CURRENT_SEQUENCE_ID', sequence_id);
        },
        fakeAct({commit}, {actId, scenarios}){
            commit('createFakeAct',{actId, scenarios});
        }

    },
    mutations:{
        createFakeAct(state, {scenarios, actId}) {
            const act = state.enquete.acts.find(a => a.id === actId)
            act.scenarios = Array.isArray(scenarios) ? scenarios : [scenarios]
        },
        addAnswer(state, {actId, scenarioId, activityId, valuestate}) {
            const act = state.enquete.acts.find(a => a.id === actId)
            const scenario = act.scenarios.find(s => s.id === scenarioId)
            const activity = scenario.activities[activityId];
            activity.userAnswer.push(valuestate.value)
        },
        removeAnswer(state, {actId, scenarioId, activityId, valuestate}) {
            const act = state.enquete.acts.find(a => a.id === actId)
            const scenario = act.scenarios.find(s => s.id === scenarioId)
            const activity = scenario.activities[activityId];
            activity.userAnswer.splice(activity.userAnswer.indexOf(valuestate.value), 1);
        },
        resetAnswers(state, {actId, scenarioId, activityId}) {
            const act = state.enquete.acts.find(a => a.id === actId)
            const scenario = act.scenarios.find(s => s.id === scenarioId)
            const activity = scenario.activities[activityId];
            activity.userAnswer = [];
        },
        replaceAnswers(state, {actId, scenarioId, activityId, valuestate}) {
            const act = state.enquete.acts.find(a => a.id === actId)
            const scenario = act.scenarios.find(s => s.id === scenarioId)
            const activity = scenario.activities[activityId];
            activity.userAnswer = valuestate.value;
        },
        async addAct(state, act){
            state.enquete.acts = [act]
        },
        async setEnqueteData(state, {cover, illustration, thumbnail, narrativeIntro, narrativeOutro, enqueteTitle}){
            state.enquete.cover = process.env.VUE_APP_MEDIA_URL + cover.data.item.path
            state.enquete.illustration = process.env.VUE_APP_MEDIA_URL + illustration.data.item.path
            state.enquete.thumbAndBgPic = process.env.VUE_APP_MEDIA_URL + thumbnail.data.item.path
            state.enquete.narrativeIntro =narrativeIntro
            state.enquete.narrativeOutro =narrativeOutro
            state.enquete.title = enqueteTitle
        }
    },
    modules:{
    }
}
