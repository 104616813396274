<template>
  <transition :name="transition">
    <router-view></router-view>
  </transition>
</template>

<script>

export default {
  name: 'App',
  components: {
  },
  data() {
    return {
      'transition': ''
    }
  },
  watch: {
    '$route.name': {
      handler: function(search) {
        if(search === 'ScoreOverview'){
          console.log(search)
          this.transition = 'component-godown'

        }
      },
      deep: true,
      immediate: true
    }
  }
}
</script>

<style lang="scss">
#app {
  @apply bg-midnight-blue;

  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  min-height: 100vh;
}

.component-goup-enter-active,
.component-goup-leave-active,
.component-godown-enter-active,
.component-godown-leave-active {
 transition: transform .5s ease-in-out;

}

.component-godown-enter-from{
  transform: translateY(-100vh);

}
.component-godown-leave-to {
  transform: translateY(100vh);
}

.component-goup-enter-from{
  transform: translateY(100vh);

}
.component-goup-leave-to {
  transform: translateY(-100vh);
}

</style>
