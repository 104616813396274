
import {client as ApolloClient} from "@/config/apolloClient";
import {parseJwt} from "../helpers";
import {createClient} from "@/config/apolloClient";
import { setUser } from "./user_log";
import { initLogTypes } from '@/composables/log_type'
import { initUserLog, addLoginLog, addLogoutLog } from '@/composables/user_log'

import {
    GC_AUTH_TOKEN,
    GC_ACCESS_TOKEN,
    GC_REFRESH_TOKEN,
    GC_REFRESH_TOKEN_EXPIRES,
  } from '@/constants/jwt'
import { ref } from 'vue';

const useAuth = () => {
    // const email = ref('');
    // const password = ref('');
    const email = ref('');
    const password = ref('');
    const remember = ref(true);
    const isLogged = ref(!!localStorage.getItem(GC_AUTH_TOKEN));
    const login = async () => {
        let user = {
            username: email.value,
            password: password.value,
            remember: remember.value,
        }

        const response = await fetch(process.env.VUE_APP_AUTH_URL + "auth/authenticate", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            credentials: 'include',
            body: JSON.stringify(user)
        })
     
        try {
            const json = await response.json()
            if (remember.value) {
                window.localStorage.setItem(GC_REFRESH_TOKEN, json.refresh.token)
                window.localStorage.setItem(GC_REFRESH_TOKEN_EXPIRES, json.refresh.expires)
            }
            if(json.id.token) {
               
                localStorage.setItem(GC_AUTH_TOKEN, json.id.token)
                localStorage.setItem(GC_ACCESS_TOKEN, json.access.token)
                isLogged.value = true
                createClient()
                                
                let user = await me(getToken()['cognito:username'])

                if (!user || !user[0]) {
                    return false
                }

                setUser(user[0])

                await initLogTypes();
                await initUserLog();
                await addLoginLog();
                return true
            }
        } catch {
            return false
        }
    }

    const sendResetEmail = async () => {
        let body = {
            username: email.value,
        }
        const response = await fetch(`${process.env.VUE_APP_AUTH_URL}auth/reset`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(body)
        })
        try {
            await response.json()
            return true

        } catch (error) {
            return false
        }
    }
    
    /**
     *
     * @returns <uuid> user_id
     */
    const userIdFromToken = () => {
        let token = getToken()
        let hasuraClaims = (token['https://hasura.io/jwt/claims'] && JSON.parse(token['https://hasura.io/jwt/claims']))
        
        return hasuraClaims && hasuraClaims['x-hasura-user-id']
    }


    const refreshToken = async () => {
        const refreshToken = localStorage.getItem(GC_REFRESH_TOKEN)
        const refreshTokenExpires = localStorage.getItem(GC_REFRESH_TOKEN_EXPIRES)
        if (!refreshToken || new Date(refreshTokenExpires) < new Date()) {
            return logout()
        }
        let data = {token: refreshToken}
        const response = await fetch(process.env.VUE_APP_AUTH_URL + "auth/authenticate", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            credentials: 'include',
            body: JSON.stringify(data)
        })

        try {
            const json = await response.json()
            if(json.id.token) {
                console.log('json.id.token):', json.id.token)
                isLogged.value = true
                localStorage.setItem(GC_AUTH_TOKEN, json.id.token)
                localStorage.setItem(GC_ACCESS_TOKEN, json.access.token)
                createClient()
                return true
            }
        } catch {
            return false
        }
    }

    const logout = async () => {
        try {
            await addLogoutLog()
            await fetch(process.env.VUE_APP_AUTH_URL + "auth/leave", {
                method: 'POST',
                headers: {
                    authorization: `Bearer ${localStorage.getItem(GC_ACCESS_TOKEN)}`
                },
            });
        } catch (e) {
            console.error(e)
        }
        
        deleteToken()
        document.location.reload()
    }



    /**
    * Retreive the token
    * @return <url>location|<string> token
    */
    const getToken =  (raw) => {
        let token = null
        let urlToken = getTokenFromUrl()

        if (!urlToken) {
            token = localStorage.getItem(GC_AUTH_TOKEN)
        }else {
            localStorage.setItem(GC_AUTH_TOKEN, urlToken)
            token = urlToken
        }

        if(token) {
            return raw ? token : parseJwt(token)
        }

        return null
    }

    /**
    * Retreive the token from url hash
    * @return <url>location|<string> token
    */
    const getTokenFromUrl = () => {
        let tokenObject = {};
        let token = null;
        let hash = window.location.hash
        if (hash && hash.includes('token')) {
            hash
                .substring(1)
                .split("&")
                .forEach(function (x) {
                    let arr = x.split("=");
                    arr[1] && (tokenObject[arr[0]] = arr[1]);
                });
            token = tokenObject.token ?? null;
        }
        return token
    }
    
    const deleteToken = async  () => {
        await localStorage.removeItem(GC_AUTH_TOKEN)
        await localStorage.removeItem(GC_ACCESS_TOKEN)
        return
    }

    /**
     * Determine if the use can connect with his token
     * @return boolean|<string>location
     */
    const hasValidToken = async () => {
        //await sleep(2000)
        let token = await getToken()
        createClient()

        if(!token) return null
        // verifier que le token n'a pas expiré
        let current_time = Date.now().valueOf() / 1000;
        if (token.exp < current_time || ! token['cognito:username']) {
            // alert('expired or invalid token')
            /* expired */
            await deleteToken()
            return null
        }

        let user = await me(token['cognito:username'])
        if(user[0]) {
            return user[0];
        }
        return null
        // On fait une query juste pour voir si tout passe bien
    }

    /**
     *
     * @param <uuid> user_id
     * @returns {Promise<*>}
     */
    const me = async (userCognitoId) =>  {
        let user = await ApolloClient.query({
            query: require('@/gql/auth/me.gql'),
            variables: {userCognitoId}
        })

        return user.data.user
    }
    

    return {
        hasValidToken,
        userIdFromToken,
        logout,
        me,
        deleteToken,
        refreshToken,
        email,
        password,
        remember,
        login,
        isLogged,
        sendResetEmail
    }
}

export {
    useAuth,
}
