export default {
    common: {
        support_email: 'supportformationsdigitales@afpols.fr'
    },
    login: {
        title: "Connectez-vous",
        identifiants_link: "Où trouver vos identifiants ?",
        password_oublier: "Mot de passe oublié ?",
        password_oublier_title: "Mot de passe oublié",
        password_oublier_send_button: "Envoyer",
        password_oublier_instruction: "Entrez votre adresse email dans le champ ci-dessous pour reinitialiser votre mot de passe. Nous vous enverrons un lien de vérification par email pour compléter l'opération. En cas de problème, contactez-nous via supportformationsdigitales@afpols.fr",
        password_oublier_success: "Merci, un email vient de vous être envoyé."
    },
    documentation: {
        bouton_telecharger: 'Télécharger',
        titre_documentation: 'Documentation',
        texte_documentation: "",
        empty_mission: 'Il n\'y a rien à consulter pour le moment, suivez les missions du niveau 1 afin de débloquer la documentation',
        empty_section: 'Il n\'y a pas de documentation pour cette section',
        empty_quest: 'Il n\'y a rien à consulter pour le moment, terminez les enquêtes du niveau 2 afin de débloquer la documentation'
    },
    aide:{
        titre_aide: "Aide",
        texte_aide: "-",
        tutoriel_title: "Comment suivre votre formation Coproxi ?",
        tutoriel_text: "Retrouvez dans ce tutoriel toutes les informations pratiques pour suivre au mieux votre formation Coproxi. ",
        bouton_telecharger: "Télécharger le tutoriel (pdf)",
        envoyer_mail: "Une question ? Envoyez-nous un mail"
    },
    certificate: {
        title: 'ATTESTATION DE RÉALISATION',
        subtitle: 'L’AFPOLS ATTESTE QUE',
        text: 'A RÉALISÉ INTÉGRALEMENT LE DISPOSITIF COPROXI',
        signature: 'Le Directeur Général Mr Franck MARTIN',
        description: `COPROXI est un dispositif de formation en ligne d’une durée
 estimée d’une ½ journée. Les objectifs sont les suivants : 
› Comprendre les fondamentaux de la copropriété.
› Identifier les nouvelles chaînes d’intervention en copropriété.
› Solliciter les bons acteurs à bon escient.
› Expliquer aux habitants le nouveau fonctionnement de la résidence.`,
    },
    scoreoverview:{
        titre_competences:	"Jauges de compétences",
        bouton_question:	"Qu'est-ce que c'est ?",
        competences_Juridique:	"Juridique",
        competences_Qualite:	"Qualité de service",
        competences_Acteur:	"Acteurs copro.",
        titre_points_gagnes: "Points gagnés"
    },
    enquete: {
        indicateur_enquete: '/4 enquêtes terminées :',
        sur_titre_enquete: "MENEZ L'ENQUÊTE !",
        texte_duree: 'Durée',
        texte_minutes: 'minutes',
        texte_gain:	'Gain',
        score_juridique:  "juridique",
        score_qualite:    "de qualité de service",
        score_acteur: "d'acteurs copro.",
        text_win: 'Bravo !',
        text_win_desc: 'L\'enquête est résolue',
        text_loose: 'Aïe...',
        text_loose_desc: 'Pas assez de points pour continuer',
        text_loose_instruction: 'Hélas, vous n’avez pas assez acquis de points de compétences pour passer à la prochaine enquête. Il va vous falloir répondre à un quiz de rattrapage pour ancrer au mieux vos connaissances. C’est à vous de jouer !'
    },
    congrats:{
        title: 'Félicitations !',
        subtitle: 'Vous avez terminé votre formation COPROXI',
    },
    acte:{
        'indicateur_actes':	'/3 ACTES TERMINÉS',
        'libelle_termine': 'TERMINÉ',
        'bouton_commencer':	'Commencer',
        'libelle_acte': 'ACTE'
    },
    placeholders: {
        email: 'Votre email',
        password: 'Votre mot de passe'
    },
    buttons: {
        login: "Se connecter",
        bouton_scolling_missions: "Niveau 1 : les missions",
        bouton_scolling_enquetes: "Niveau 2 : les enquêtes",
        bouton_certificate: "Télécharger mon attestation",
        bouton_telecharger_PDF: "Télécharger le PDF",
        bouton_play_video: "Lire la video",
        bouton_continuer: "Continuer",
        bouton_documentation: "Accés à la documentation",
        bouton_retour:'Retour',
        bouton_retour_accueil: 'Retour à l\'accueil',
        bouton_lancer: 'Lancer l\'enquête',
        bouton_retry: 'Réessayer',
        bouton_validate: 'Valider',
        bouton_listenagain: 'Ré-écouter',
        ask_for_result: 'Voir les réponses',
        loading: 'Chargement...',
    },
    header:{
        bouton_COPROXI: 'COPROXI',
        bouton_Documentation: 'Documentation',
        bouton_Aide: 'Aide',
        bouton_Compte: 'Déconnexion',
        bouton_niveau1: 'Niveau 1',
        bouton_niveau1_retry: 'Niveau 1 (recommencer)',
        bouton_niveau2: 'Niveau 2',
    },
    gameplay_classic:{
        bouton_selectionner: 'Sélectionner'
    },
    videos: {
        bouton_interroger: 'Interroger',
        bouton_repondre: 'Décrocher',
        etat_appel_termine: 'Appel terminé',
        bouton_rejouer: 'Re-jouer',
        bouton_continuer: 'Continuer',
        bouton_reesayer: 'Réesayer'
    },
    expert: {
        nom_expert: 'Laurent, l\'expert'
    },
    footer:{
      wrong_answer: 'Mauvaise réponse',
      right_answer: 'Bravo !',
      body_prefix: 'Vous avez gagné',
      body_suffix: 'points en',
      body_before_suffix: 'point',
      body_plural_suffix: 's',
      body_after_suffix: ' en'
    }
}
