<template>
  <div :class="`absolute w-screen h-screen flex text-yellow-light bg-opacity-90 justify-start bg-cover items-center bg-center bg-buildings`">
    <div class="absolute w-screen h-screen flex  flex-col align-center text-yellow-light bg-midnight-blue bg-opacity-95 justify-center items-center">
    <div>
      <h1 class="text-yellow-light font-coustard-black text-6xl mb-20 ">{{$t.scoreoverview.titre_points_gagnes}}</h1>
        <ScoreBox @status="retrieveScore($event)" :moduleID="currentModule" :is-home="isHome"></ScoreBox>
      <div class="flex justify-center">
        <ShadowButton max-width="300px" theme="yellow-light" icon="down-arrow-midnight" @click="GoToNextPage">Continuer</ShadowButton>
      </div>
      </div>
    </div>
  </div>
</template>

<script>
  import ShadowButton from "../components/ShadowButton";
  import ScoreBox from "../components/score-box/ScoreBox";
  import { mapState } from 'vuex'

  export default {
    components: {ShadowButton, ScoreBox},
    data () {
      return {
        'moduleWin':this.win,
        'bypassed': this.bypass
      }
    },
    props:{
      isHome: {
        type: String,
        default: '',
        required: false
      },
      win: {
        type: Boolean,
        default: true
      },
      bypass: {
        type: Boolean,
        default: false
      }
    },
    mounted() {
    },
    methods: {
      retrieveScore(status){
        this.moduleWin = status === 'win'
      },
      GoToNextPage(){
        if (this.isHome === 'true'){
          this.$router.push({ name: 'Home', params: {bypass: this.bypass ? 'true' : undefined}})
        } else {
          if (this.moduleWin){
            this.$router.push({name: 'enquete/EnqueteWrapper', params: {noIntro: 'bravo'}})
          }else{
            this.$router.push({name: 'enquete/EnqueteWrapper', params: {noIntro: 'aie'}})
          }
        }
      }
    },
    computed: {
      ...mapState({
        currentModule: state => {
          return state.gamestatus.currentModule
        }
      })
    }
  }
</script>

<style lang="scss" scoped>

</style>
