import { h } from 'vue'

export default {
	props: {
		tag: {
			type: String,
			required: true,
		},
		text: {
			type: String,
			required: true,
		},
		className: {
			type: String,
			required: false,
		},
	},
	setup(props) {
		return () => {
			if (!props.text) {
				return null
			}

			return h(props.tag, {
				'class': props.className
			}, props.text.split('\n').reduce((accumulator, string) => {
				if (!Array.isArray(accumulator)) {
					return [accumulator, h('br'), string]
				}
				return accumulator.concat([h('br'), string])
			}))
		} 
	}
}