export const GameType = {
    PHOTO: 'hotspots', // DONE
    QCM: 'quiz', // DONE
    ACTORS: 'quiz_picture', // DONE
    DRAGDROP: 'dragdrop_picture', // DONE
    DRAGDROPTEXT: 'dragdrop',
    NARRATIVE: 'narrator',
    VIDEOSIMPLECARDS: 'message_gallery', // DONE

    // Ces deux là sont a regrouper puis faire le choix du component en fonction du fait que l'activité soit en mode "avec" ou "sans" reponse.
    VIDEOINTERACTIVE: 'conversation',
    VIDEODEROULEMENTLOCK: 'conversation',

    YESNO: 'true_false', // DONE
    VIDEOANSWER: 'conversation',
    VIDEOREPONDEUR: 'message_phone'
};
export const GameStatus = {
    SUCCESS: 'success',
    ERROR: 'error',
    NONE: undefined
};
export const GameMode = {
    EXACT: 'exact', // User have to answer exact type of questions (quizz..)
    ALLIN: 'allin', // User have to have answered all question (when it do not require real answer (SIMPLECARD...))
};
export const CourseType = {
    MISSION: 'mission',
    QUEST: 'investigation',
};
export const MediaFormat = {
    visio: 'visio',
    phone: 'phone',
    image: 'image',
    audio: 'audio'
};


// LEGAL / LAW is the same but seems like there is both in DB??
export const ScoreType = {
    LAW: 'law',
    LEGAL: 'legal',
    ACTOR: 'actor',
    QUALITY: 'quality',
};
